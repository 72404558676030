import { fromZonedTime } from "date-fns-tz";
import { defaultTimeZone } from "./constants";

const formatDurationHelperMilliseconds = (_milliseconds: number) => {
  let minutes = Math.floor(_milliseconds / (1000 * 60));
  const milliseconds = _milliseconds - minutes * 60 * 1000;

  if (milliseconds) minutes += 1;

  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;

  const result = { days: 0, hours: 0, minutes: 0 };

  if (days) result.days = days;
  if (remainingHours) result.hours = remainingHours;
  if (remainingMinutes) result.minutes = remainingMinutes;

  return result;
};
const formatDurationHelper = (startDate: Date, endDate: Date) => {
  return formatDurationHelperMilliseconds(
    endDate.getTime() - startDate.getTime()
  );
};

export const formatDurationForMinutes = (_minutes: number): string => {
  const { days, hours, minutes } = formatDurationHelperMilliseconds(
    _minutes * 1000 * 60
  );
  return `${days > 0 ? `${days} day${days > 1 ? "s" : ""} ` : ""}${
    hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""
  }${minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""} ` : ""}`.trim();
};

export const formatDateDuration = (startDate: Date, endDate: Date): string => {
  const { days, hours, minutes } = formatDurationHelper(startDate, endDate);
  return `${days > 0 ? `${days} day${days > 1 ? "s" : ""} ` : ""}${
    hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""
  }${minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""} ` : ""}`.trim();
};

export const formatDateDurationShort = (
  startDate: Date | string,
  endDate: Date | string
): string => {
  const { days, hours, minutes } = formatDurationHelper(
    new Date(startDate),
    new Date(endDate)
  );
  return `${days > 0 ? `${days} d ` : ""}${hours > 0 ? `${hours} h ` : ""}${
    minutes > 0 ? `${minutes} min` : ""
  }`.trim();
};

// unlike date-fns subSeconds(new Date(), seconds) not rounding seconds
export const subSecondsFromNow = (seconds: number) =>
  new Date(Date.now() - seconds * 1000);

/**
 * Parses date string without timezone offset or UTC sign
 * using default app time zone (e.g. Europe/Helsinki)
 */
export const localStringToDate = (date: Date | string) =>
  typeof date !== "string"
    ? date
    : /[Z\-+]/.test(date.split("T")[1] || "")
    ? new Date(date)
    : fromZonedTime(date, defaultTimeZone);
