import client, { AxiosRequestConfig } from "api/client";
import { chatbotBackendUrl } from "config";
import { Bot, chat, createChat, first } from "./apiPaths";

type ChatPayload = { question: string };

export type ChatResponse = {
  chat_id: string;
  conversation_has_ended: boolean;
  response: string;
};

type FirstMessageResponse = {
  message: string;
};

export const botClient = (bot: Bot) => ({
  startChat: async (payload: ChatPayload) => {
    const response = await client.post<ChatResponse>(
      `${chatbotBackendUrl}${createChat(bot)}`,
      payload
    );
    return response.data;
  },

  askInChat: async (chatId: string, payload: ChatPayload) => {
    const response = await client.put<ChatResponse>(
      `${chatbotBackendUrl}${chat(bot, chatId)}`,
      payload
    );
    return response.data;
  },

  getFirstMessage: async (config: Partial<AxiosRequestConfig> = {}) => {
    const response = await client.get<FirstMessageResponse>(
      `${chatbotBackendUrl}${first(bot)}`,
      config
    );
    return response.data;
  },
});
