import client from "api/client";
import { DoorDb } from "common/models/doors/types";
import { door, doorOpen } from "./apiPaths";

export async function getDoorByPath(doorPath: string) {
  const response = await client.get<DoorDb>(door(doorPath));
  return response.data;
}

export async function openDoorByPath(doorPath: string, plateText: string) {
  const payload = { plateText };
  const response = await client.post<boolean>(doorOpen(doorPath), payload);
  return response.data;
}
