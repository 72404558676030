import client from "api/client";
import { PlateHallSpacesResponse } from "common/models/owners/types";
import { Plate } from "common/types";
import { spaces } from "./apiPaths";

export async function getSpaces(params: Plate) {
  const response = await client.get<PlateHallSpacesResponse>(spaces, {
    params,
  });
  return response.data;
}
