import { Plate } from "common/types";
import {
  PlateTextField as PlateTextFieldDumb,
  PlateTextFieldProps,
} from "components/PlateTextField";
import { FormState, UseFormRegister } from "react-hook-form";
import { useVisits } from "../../../store/useVisits";

type Props =
  | {
      register: UseFormRegister<Plate>;
      formState: FormState<Plate>;
    }
  | { register?: undefined; formState?: undefined };

export const PlateTextField = ({ register, formState }: Props) => {
  const { loading: visitsLoading, isInitial, plate, unpaidData } = useVisits();
  const loading = visitsLoading || isInitial;
  const { visitIds, freeParkingEnd } = unpaidData;
  const hasUnpaid = !!visitIds.length;
  const hasPending = !!freeParkingEnd;
  const hasResults = hasUnpaid || hasPending;
  const editProps: PlateTextFieldProps = register
    ? { register, formState }
    : { value: plate?.plateText };

  return (
    <PlateTextFieldDumb
      loading={loading}
      hasResults={hasResults}
      {...editProps}
    />
  );
};
