import { withRetry } from "@asantech/common/common/requestUtils";
import { Loader } from "@asantech/common/react/Loader";
import { Trans } from "@lingui/macro";
import { useAsync } from "@react-hookz/web";
import { getPaidVisits } from "api/visits";
import { useHandleFinishedFn } from "common/hooks/useHandleFinishedFn";
import { PaidVisitsResponse } from "common/models/parkkimaksu/types";
import { Pages } from "common/pages";
import { getUrlPaymentId, PAYMENT_ID_SEARCH_PARAM } from "common/stripe";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Visits } from "views/visits/Visits";
import { useVisits } from "../store/useVisits";
import { Button } from "./Button";
import { WidthContainer } from "./Container";
import { Content } from "./Content";
import { Euros } from "./Euros";
import { IdleTimerBar } from "./IdleTimerBar";
import { StatusIcon } from "./StatusIcon";
import { StickyBottom } from "./StickyBottom";

type Props = {
  allowReceiptRequest?: boolean;
};

export const PaymentSuccess = ({ allowReceiptRequest }: Props) => {
  const history = useHistory();
  const handleFinished = useHandleFinishedFn();

  const paymentId = getUrlPaymentId();

  const handleReceipt = useCallback(() => {
    if (!paymentId) return;
    history.push({
      pathname: Pages.ReceiptRequest,
      search: new URLSearchParams({
        [PAYMENT_ID_SEARCH_PARAM]: paymentId,
      }).toString(),
    });
  }, [history, paymentId]);

  const [{ result: paid, error, status }, { execute: fetchPaidVisits }] =
    useAsync(
      withRetry(
        async (paymentId: string) => {
          const result = await getPaidVisits(paymentId);
          if (!result.visits.length)
            throw new Error("Could not fetch paid visits.");
          return result;
        },
        2000,
        30
      ) as unknown as (paymentId: string) => Promise<PaidVisitsResponse>
    );

  useEffect(() => {
    if (paymentId) fetchPaidVisits(paymentId);
  }, [fetchPaidVisits, paymentId]);

  if (!paymentId) {
    history.push(Pages.Home);
    return null;
  }

  const loading = status === "loading" || status === "not-executed";

  return (
    <Content>
      <StatusIcon />
      <WidthContainer>
        <Status>
          <h3>
            {paid &&
              (paid.visits.length > 1 ? (
                <Trans>Your parkings have been paid</Trans>
              ) : (
                <Trans>Your parking has been paid</Trans>
              ))}
          </h3>
        </Status>
        <VisitsContainer>
          {!!paid?.visits.length && (
            <Visits paymentId={paymentId} visits={paid?.visits} />
          )}
          {loading && !error ? (
            <Loader />
          ) : error?.message ? (
            <span>{error.message}</span>
          ) : (
            <></>
          )}
        </VisitsContainer>
        {paid !== undefined && (
          <Total>
            <TotalKeyPair>
              <Label>
                <Trans>Total amount charged:</Trans>
              </Label>
              <Value>
                <Euros cents={paid.price} />
              </Value>
            </TotalKeyPair>

            {paid.feePerVisit > 0 && (
              <TotalKeyPair>
                <Label>
                  <Trans>Includes service fee per parking:</Trans>
                </Label>
                <Value>
                  <Euros cents={paid.feePerVisit} />
                </Value>
              </TotalKeyPair>
            )}
            {paid.tax.map(({ rate, amount }) => (
              <TotalKeyPair key={rate}>
                <Label>
                  <Trans>Includes VAT {rate} %:</Trans>
                </Label>
                <Value>
                  <Euros cents={amount} />
                </Value>
              </TotalKeyPair>
            ))}
          </Total>
        )}
      </WidthContainer>
      <StickyBottom>
        <Buttons>
          {allowReceiptRequest && (
            <Button variant="secondary" onClick={handleReceipt}>
              <Trans>Send receipt</Trans>
            </Button>
          )}
          <Button variant="secondary" onClick={handleFinished}>
            <Trans>Okay</Trans>
            <IdleTimerBar />
          </Button>
        </Buttons>
      </StickyBottom>
    </Content>
  );
};

export const PaymentFailure = () => {
  const history = useHistory();
  const { unpaidData, paymentError } = useVisits();
  const handleTryAgain = useCallback(() => {
    history.push(Pages.Unpaid);
  }, [history]);

  return (
    <Content>
      <StatusIcon variant icon="error" />
      <WidthContainer>
        <Status>
          <h3>
            <Trans>Payment failed</Trans>
          </h3>
        </Status>
        <VisitsContainer>
          <h3>
            {paymentError}{" "}
            {unpaidData.visitIds.length === 1 ? (
              <Trans>Parking is still unpaid.</Trans>
            ) : (
              <Trans>
                {unpaidData.visitIds.length + " "} parkings are still unpaid.
              </Trans>
            )}
          </h3>
        </VisitsContainer>
      </WidthContainer>
      <StickyBottom>
        <Button variant="secondary" onClick={handleTryAgain}>
          <Trans>Try again</Trans>
          <IdleTimerBar />
        </Button>
      </StickyBottom>
    </Content>
  );
};

const Total = styled.div`
  color: var(--text-secondary);
  margin: 0 0 20px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
`;

const TotalKeyPair = styled.div`
  margin-bottom: var(--margin);
`;

const Label = styled.div``;

const Value = styled.div`
  color: var(--text-highlight);
`;

export const Status = styled.div`
  h3 {
    font-size: var(--fs-biggish);
    font-weight: 700;
    text-align: center;
    padding-top: 5px;
    line-height: normal;
  }
`;

export const VisitsContainer = styled.div`
  padding: 20px 0;
  text-align: center;

  div {
    text-align: left;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
