export const CLIENT_SECRET_SEARCH_PARAM = "payment_intent_client_secret";
export const PAYMENT_ID_SEARCH_PARAM = "payment_transaction_id";

const getSearchValue = (key: string) =>
  new URLSearchParams(window.location.search).get(key);

export const getUrlClientSecret = () =>
  getSearchValue(CLIENT_SECRET_SEARCH_PARAM);

export const getUrlPaymentId = () => getSearchValue(PAYMENT_ID_SEARCH_PARAM);
