import { Pages } from "common/pages";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useVisits } from "store/useVisits";

export const useHandleFinishedFn = () => {
  const { resetPlate } = useVisits();
  const history = useHistory();
  return useCallback(() => {
    resetPlate();
    history.push(Pages.Home);
  }, [history, resetPlate]);
};
