export const createPaymentIntent = "/stripe/create-payment-intent";
export const stripeConfig = "/stripe/config";
export const netsStartPayment = "/nets/start-payment";
export const netsPaymentStatus = "/nets/payment-status";
export const netsTerminalDetails = "/nets/terminal-details";
export const netsTerminalSettings = "/nets/terminal-settings";
export const receipt = "/parkkimaksu/receipt";
export const pendingData = "/parkkimaksu/pending";
export const leaveHall = "/parkkimaksu/leave";
export const receipts = "/parkkimaksu/receipts";
export const unpaidData = "/parkkimaksu/unpaid";
export const paidVisits = "/parkkimaksu/paid";
export const door = (path: string) => `/doors/${path}`;
export const doorOpen = (path: string) => `/doors/${path}/open`;
export const spaces = "/parkkimaksu/spaces";

export const debugPaymentSuccess = "/debug/payment-success";
