export const openApiUserBase = {
  type: "object",
  required: ["email"],
  properties: {
    email: {
      type: "string",
      format: "email",
    },
    isInvited: {
      type: "boolean",
    },
  },
};

export const openApiUserInput = {
  type: "object",
  required: openApiUserBase.required,
  properties: {
    ...openApiUserBase.properties,
    isNew: {
      type: "boolean",
    },
  },
};

export const openApiUser = {
  type: "object",
  required: [...openApiUserBase.required, "isInvited"],
  properties: {
    ...openApiUserBase.properties,
    error: {
      type: "string",
    },
  },
};
