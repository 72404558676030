import { Loader } from "@asantech/common/react/Loader";
import { useAsync, useDebouncedEffect } from "@react-hookz/web";
import { getNetsTerminalDetails } from "api/payments";
import { getTerminalToken, setTerminalToken } from "common/localStorage";
import { parseToken } from "common/utils";
import { Alert } from "components/Alert";
import { Container, WidthContainer } from "components/Container";
import { Content } from "components/Content";
import { Label } from "components/Label";
import { StatusIcon } from "components/StatusIcon";
import { TextFieldInput } from "components/TextField";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

export const TerminalPage = () => {
  const [token, setToken] = useState(getTerminalToken() || "");
  const [
    {
      result: terminalDetails,
      status: terminalDetailsStatus,
      error: terminalDetailsError,
    },
    { execute: getTerminalDetails, reset: resetTerminalDetails },
  ] = useAsync(getNetsTerminalDetails);

  const handleTokenChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      resetTerminalDetails();
      setToken(e.target.value);
    },
    [resetTerminalDetails]
  );

  const terminalId = useMemo(() => {
    try {
      const tokenInfo = parseToken(token);
      return tokenInfo.terminalId;
    } catch (e) {
      return "";
    }
  }, [token]);

  useDebouncedEffect(
    () => {
      setTerminalToken(token);
      if (token) getTerminalDetails();
    },
    [token, getTerminalDetails],
    500
  );

  return (
    <Container>
      <TopBar>
        <WidthContainer>Terminal {terminalId}</WidthContainer>
      </TopBar>
      <WidthContainer>
        <Label>Terminal token:</Label>
        <TextFieldInput
          value={token}
          name="terminalToken"
          onChange={handleTokenChange}
          variant="secondary"
        />
        <Label>Terminal details:</Label>
        {terminalDetailsStatus === "loading" ? (
          <Loader />
        ) : !!terminalDetailsError || !token ? (
          <ContentStyled>
            <StatusIcon icon="error" />
            <WidthContainer>
              <Alert>
                <h3>
                  {token
                    ? "Fetching details failed."
                    : "No terminal token provided"}
                </h3>
              </Alert>
            </WidthContainer>
          </ContentStyled>
        ) : (
          terminalDetails && (
            <Info>
              {Object.entries(terminalDetails).map(([key, value]) => (
                <Row key={key}>
                  <Value>{key}</Value>
                  <Value>{value}</Value>
                </Row>
              ))}
            </Info>
          )
        )}
      </WidthContainer>
    </Container>
  );
};

const TopBar = styled.div`
  background: var(--secondary);
  height: 59px;
  line-height: 59px;
`;

const ContentStyled = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Info = styled.div`
  padding: 12px 20px 8px;
  margin: 0;
  background: var(--secondary);
  letter-spacing: 0.36px;
  display: flex;
  flex-direction: column;
`;

const Value = styled.div<{ warn?: boolean; success?: boolean }>`
  font-weight: 500;
  ${(p) => p.success && "color: #5fcc70;"}
  ${(p) => p.warn && "color: #ffbb0c;"}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
`;
