import { getTerminalToken } from "common/localStorage";
import { Pages } from "common/pages";
import { ChildrenOnly } from "common/types";
import { docModified } from "common/utils";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

type PageTimeouts = Record<string, number | null | (() => number | null)> & {
  default: number;
};

const pageTimeoutsSec: PageTimeouts = {
  [Pages.PaymentComplete]: 20,
  [Pages.ReceiptRequest]: 60,
  [Pages.Payment]: () => (getTerminalToken() ? null : 45),
  default: 45,
};

type IdleTimeoutContextType = {
  clearIdleTimeout: () => void;
  restartIdleTimeout: (delaySeconds?: number) => void;
  resetIdleTimeout: () => void;
  timer: { start: Date; end: Date } | undefined;
  lastTimedOutAt: Date | undefined;
};

const IdleTimeoutContext = createContext<IdleTimeoutContextType | undefined>(
  undefined
);

export const IdleTimeoutProvider = ({ children }: ChildrenOnly) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const terminalToken = getTerminalToken();
  const history = useHistory();
  const { pathname } = useLocation();
  const [lastTimedOutAt, setLastTimedOutAt] = useState<Date | undefined>();
  const [timer, setTimer] = useState<{ start: Date; end: Date } | undefined>(
    undefined
  );

  const clearIdleTimeout = useCallback(() => {
    setTimer(undefined);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }, []);

  const restartIdleTimeout = useCallback(
    (seconds?: number) => {
      const delayDefinition =
        seconds !== undefined
          ? seconds
          : pageTimeoutsSec[pathname] === null
          ? null
          : pageTimeoutsSec[pathname] || pageTimeoutsSec.default;

      if (!terminalToken) return;

      clearIdleTimeout();
      const delaySeconds =
        typeof delayDefinition === "function"
          ? delayDefinition()
          : delayDefinition;

      if (delaySeconds === null) return;
      const delayMs = delaySeconds * 1000;
      const start = new Date();
      const end = new Date(start.getTime() + delayMs);
      setTimer({ start, end });
      timeoutRef.current = setTimeout(async () => {
        setLastTimedOutAt(new Date());
        if (pathname === Pages.Home) {
          if (await docModified()) {
            window.location.reload();
          } else {
            restartIdleTimeout();
          }
        }
      }, delayMs);
    },
    [clearIdleTimeout, pathname, terminalToken]
  );

  const resetIdleTimeout = useCallback(() => {
    if (timeoutRef.current) restartIdleTimeout();
  }, [restartIdleTimeout]);

  useEffect(() => {
    restartIdleTimeout();

    window.addEventListener("touchstart", resetIdleTimeout);
    window.addEventListener("scroll", resetIdleTimeout);
    window.addEventListener("keypress", resetIdleTimeout);

    return () => {
      window.removeEventListener("touchstart", resetIdleTimeout);
      window.removeEventListener("scroll", resetIdleTimeout);
      window.addEventListener("keypress", resetIdleTimeout);
    };
  }, [restartIdleTimeout, resetIdleTimeout, history, pathname]);

  const value = {
    clearIdleTimeout,
    restartIdleTimeout,
    resetIdleTimeout,
    timer,
    lastTimedOutAt,
  };

  return (
    <IdleTimeoutContext.Provider value={value}>
      {children}
    </IdleTimeoutContext.Provider>
  );
};

export const useIdleTimeout = () => {
  const context = useContext(IdleTimeoutContext);
  if (context === undefined) {
    throw new Error("useIdleTimeout must be used within IdleTimeoutContext");
  }
  return context;
};
