import {
  FieldSize,
  FieldVariant,
  TextField as TextFieldOrig,
  TextFieldProps,
} from "@asantech/common/react/TextField/TextField";
import { FieldValues } from "react-hook-form";
import styled from "styled-components";
export type { TextFieldProps } from "@asantech/common/react/TextField/TextField";

export const TextField = <T extends FieldValues>(props: TextFieldProps<T>) => (
  <TextFieldOrig Element={TextFieldInput} {...props} />
);

export const TextFieldInput = styled.input<{
  variant: FieldVariant;
  invalid?: boolean;
  fieldSize?: FieldSize;
  disabled?: boolean;
}>`
  display: inline-block;
  width: 100%;
  border: 1px solid transparent;
  color: var(--text-primary);
  font-size: var(--fs-medium);
  padding: 0.75rem;
  background-color: var(--input-bg);
  border-radius: 16px;
  ${({ disabled }) => (disabled ? "opacity: 0.4;" : "")}
`;
