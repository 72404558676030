import { Container } from "components/Container";
import { Footer } from "components/Footer";
import { PaymentFailure } from "../components/PaymentStatus";

export const PaymentFailedPage = () => {
  return (
    <Container>
      <PaymentFailure />
      <Footer />
    </Container>
  );
};
