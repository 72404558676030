import { Plate } from "common/types";
import { isTerminal } from "common/utils";
import { TextField } from "components/TextField";
import { FormState, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import { TextFieldInput } from "./TextField";

export type PlateTextFieldProps = {
  loading?: boolean;
  isSubmitted?: boolean;
  hasResults?: boolean;
  readOnly?: boolean;
} & (
  | {
      register: UseFormRegister<Plate>;
      formState: FormState<Plate>;
      value?: undefined;
    }
  | {
      register?: undefined;
      formState?: undefined;
      value?: string;
    }
);

export const PlateTextField = ({
  register,
  formState,
  loading,
  hasResults,
  value,
  readOnly,
  isSubmitted,
}: PlateTextFieldProps) => {
  const defaultSubmitted = formState ? formState.isSubmitted : !loading;

  return (
    <PlateTextFieldWrapper
      disabled={isTerminal}
      isSubmitted={isSubmitted !== undefined ? isSubmitted : defaultSubmitted}
      hasResults={hasResults}
    >
      {register && !readOnly ? (
        <TextField
          register={register}
          placeholder="ABC123"
          name="plateText"
          variant="secondary"
          errors={formState.errors.plateText}
          required
        />
      ) : (
        <TextFieldInput
          value={register ? undefined : value || ""}
          {...(register ? register("plateText") : {})}
          placeholder="ABC123"
          variant="secondary"
          name="plateText"
          readOnly
          required
        />
      )}
    </PlateTextFieldWrapper>
  );
};

export const PlateTextFieldWrapper = styled.fieldset<{
  isSubmitted?: boolean;
  hasResults?: boolean;
}>`
  margin: 0;
  margin-top: 20px;
  padding: 0;
  border-radius: 20px;
  border: 3px solid
    ${({ isSubmitted, hasResults }) =>
      isSubmitted && !hasResults
        ? "var(--alert-fail)"
        : isSubmitted && hasResults
        ? "var(--alert-success)"
        : "transparent"};

  & input {
    font-size: var(--fs-big);
    letter-spacing: 3px;
    height: 60px;
    text-align: center;
  }
`;
