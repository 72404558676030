import { Loader } from "@asantech/common/react/Loader";
import { Trans } from "@lingui/macro";
import { useAsync } from "@react-hookz/web";
import { getReceipts } from "api/visits";
import { Pages } from "common/pages";
import { Content } from "components/Content";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Container, WidthContainer } from "../components/Container";

export const ReceiptsPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const paymentIds = useMemo(
    () => new URLSearchParams(search).getAll("paymentId[]"),
    [search]
  );
  const [{ status, result: receipts, error }, { execute: fetchReceipts }] =
    useAsync(getReceipts);
  const isLoading = ["not-executed", "loading"].includes(status);

  useEffect(() => {
    fetchReceipts(paymentIds);
  }, [fetchReceipts, paymentIds]);

  if (!paymentIds.length) {
    history.push(Pages.Home);
    return null;
  }

  return (
    <Container>
      <Header />
      <Content>
        <WidthContainer>
          {isLoading && <Loader />}
          {!isLoading && error && (
            <CenteredText>
              <Trans>Error fetching receipts</Trans>
            </CenteredText>
          )}
          {!isLoading && !error && !!receipts?.length && (
            <>
              {receipts.map((r) => (
                <Receipt key={r.paymentId}>{r.receiptText}</Receipt>
              ))}
            </>
          )}
          {!isLoading && !error && !receipts?.length && (
            <CenteredText>
              <Trans>No receipts found</Trans>
            </CenteredText>
          )}
        </WidthContainer>
      </Content>
      <BrandAwareFooter />
    </Container>
  );
};

const CenteredText = styled.span`
  padding-top: 100px;
  text-align: center;
  font-size: 16px;
`;

const Receipt = styled.div`
  margin: 65px 0;
  white-space: pre-line;
  font-size: 16px;
`;
