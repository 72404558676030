import { useMountEffect } from "@react-hookz/web";
import { getTerminalToken } from "common/localStorage";
import { Pages } from "common/pages";
import { BackButton } from "components/BackButton";
import { Container, WidthContainer } from "components/Container";
import { useStripeProvider } from "components/stripe/StripeProvider";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useVisits } from "store/useVisits";
import styled from "styled-components";
import { TerminalPaymentForm } from "views/payments/TerminalPaymentForm";
import PaymentForm from "../components/stripe/PaymentForm";

export const PaymentPage = () => {
  const history = useHistory();
  const { clientSecret } = useStripeProvider();
  const terminalToken = getTerminalToken();
  const { terminalRequest, refreshUnpaidData } = useVisits();
  useMountEffect(() => refreshUnpaidData());

  const handleBack = useCallback(() => {
    history.push(Pages.Unpaid);
  }, [history]);

  useEffect(() => {
    if (
      (!terminalToken && !clientSecret) ||
      (terminalToken && !terminalRequest)
    ) {
      handleBack();
    }
  }, [clientSecret, terminalToken, terminalRequest, handleBack]);

  return (
    <Container>
      <TopBar>
        <WidthContainer>
          <TopBarContent>
            {!terminalToken && <BackButton onClick={handleBack} />}
          </TopBarContent>
        </WidthContainer>
      </TopBar>
      <PaymentContent>
        {terminalToken ? (
          <TerminalPaymentForm />
        ) : (
          clientSecret && <PaymentForm />
        )}
      </PaymentContent>
    </Container>
  );
};

const TopBar = styled.div`
  padding: 0 var(--margin);
  background: var(--secondary);
`;

const TopBarContent = styled.div`
  display: flex;
  align-items: center;
  height: 59px;
`;

const PaymentContent = styled(WidthContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 17px var(--margin);
  height: 100%;
  color: var(--text-primary);

  h2 {
    font-size: var(--fs-biggish);
    font-weight: 600;
    padding-bottom: 10px;
    letter-spacing: 0.48px;
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 18.4px;
  }
`;
