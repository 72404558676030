import { APIGatewayEvent } from "aws-lambda";
import { AuthenticatedUser } from "common/models/users/types";

export type GatewayEvent = Pick<
  APIGatewayEvent,
  | "body"
  | "headers"
  | "multiValueHeaders"
  | "httpMethod"
  | "path"
  | "pathParameters"
  | "queryStringParameters"
  | "multiValueQueryStringParameters"
> & {
  user?: AuthenticatedUser;
};

export type Tuple<T, N extends number, A extends T[] = []> = A extends {
  length: N;
}
  ? A
  : Tuple<T, N, [...A, T]>;

export const openApiId = {
  type: "integer",
  minimum: 0,
  example: 5,
  description: "Resource identifier",
};

export const openApiName = {
  type: "string",
  minimum: 1,
  example: "resource-name",
  description: "Resource identifier",
};

export const openApiUuid = {
  type: "string",
  format: "uuid",
  example: "79ee59ad-6b76-4c65-a486-fac59922bc7c",
  description: "Universally unique resource identifier",
};

export const openApiDate = {
  type: "string",
  format: "date-time",
  example: "2021-01-01T10:00:00.000Z",
  description: "Date in ISO 8601 format",
};
