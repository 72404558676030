import { countryCodes } from "../../common/constants";
import { openApiId } from "../../common/types";

export type PlateInput = {
  country: string;
  text: string;
  regionCode?: string | null;
  ownerId: number;
  expiresAt?: string | Date | null;
};

export type PlateDb = PlateInput & {
  id: number;
};

export type Plate = PlateDb;

export type VisitPlate = {
  plateText: string;
  plateCountry: string;
  regionCode?: string | null;
};

export type PlateQuery = {
  plateText: string;
  plateCountry?: string;
};

export const openApiPlateText = {
  type: "string",
  minLength: 1,
  maxLength: 255,
  example: "ABC123",
  description:
    "Vehicle's plate text. All whitespace characters and dashes are ignored. ",
};

export const openApiPlateCountry = {
  type: "string",
  enum: countryCodes,
  example: "FIN",
  description: "Plate country in the ISO 3166 Alpha-3 code format.",
};

export const openApiRegionCode = {
  type: "string",
  minLength: 1,
  maxLength: 255,
  nullable: true,
  example: "A",
  description:
    "Vehicle's region for when country and text together are not unique. ",
};

export const openApiPersonPlateInput = {
  type: "object",
  required: ["text", "country"],
  properties: {
    text: openApiPlateText,
    country: openApiPlateCountry,
    regionCode: openApiRegionCode,
  },
};

export const openApiPlateInput = {
  type: "object",
  required: openApiPersonPlateInput.required,
  properties: {
    ...openApiPersonPlateInput.properties,
    expiresAt: {
      type: "string",
      description:
        "Expiration date for plate. It will be deleted after that time.",
      format: "date-time",
      nullable: true,
    },
  },
};

export const openApiPlate = {
  type: "object",
  required: ["id", "ownerId", ...openApiPlateInput.required],
  properties: {
    id: openApiId,
    ownerId: openApiId,
    ...openApiPlateInput.properties,
  },
};

export const openApiPlateQuery = {
  type: "object",
  required: ["plateText"],
  properties: {
    plateText: {
      type: "string",
      minLength: 1,
    },
    plateCountry: {
      type: "string",
      minLength: 3,
      maxLength: 3,
    },
  },
};
