export enum Pages {
  Home = "/",
  Unpaid = "/unpaid",
  Payment = "/payment",
  PaymentComplete = "/payment-complete",
  PaymentFailure = "/payment-failure",
  Terminal = "/terminal",
  Receipts = "/receipts",
  ReceiptRequest = "/receipt",
  CustomerService = "/customer-service",
  LeaveComplete = "/leave-complete",
  DemoBot = "/demobotti",
  Door = "/doors",
  Space = "/tila",
}
