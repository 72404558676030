import { Trans } from "@lingui/macro";
import { Pages } from "common/pages";
import { getUrlPaymentId } from "common/stripe";
import { BackButton } from "components/BackButton";
import { Content } from "components/Content";
import { BrandAwareFooter } from "components/Footer";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container, WidthContainer } from "../components/Container";
import { ReceiptRequest } from "../components/ReceiptRequest";

export const ReceiptRequestPage = () => {
  const history = useHistory();
  const paymentId = getUrlPaymentId();

  const handleBack = useCallback(() => {
    history.push(Pages.Home);
  }, [history]);

  if (!paymentId) {
    history.push(Pages.Home);
    return null;
  }
  return (
    <Container>
      <TopBar>
        <WidthContainer>
          <TopBarContent>
            <BackButton onClick={handleBack} />
          </TopBarContent>
        </WidthContainer>
      </TopBar>
      <Content>
        <WidthContainer>
          <Header>
            <Trans>Payment receipt</Trans>
          </Header>
        </WidthContainer>
        <ReceiptRequest paymentId={paymentId} />
      </Content>
      <BrandAwareFooter />
    </Container>
  );
};

const TopBar = styled.div`
  padding: 0 20px;
  background: var(--secondary);
  height: 59px;
  button {
    display: flex;
    align-items: center;
    height: 59px;
  }
`;

const TopBarContent = styled.div`
  display: flex;
  align-items: center;
  height: 59px;
`;

const Header = styled.div`
  padding: 16px 0;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.48px;
`;
