import { Variant } from "common/types";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { ButtonLoader } from "../components/ButtonLoader";

export type ButtonProps = {
  variant?: Variant;
  stretch?: boolean;
  bare?: boolean;
  loading?: boolean;
};

export const Button: React.FC<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ loading, children, disabled, ...rest }) => {
  return (
    <StyledButton disabled={loading || disabled} {...rest}>
      {loading && <StyledLoader />}
      {children}
    </StyledButton>
  );
};

const StyledLoader = styled(ButtonLoader)`
  z-index: -1;
`;

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  z-index: 1;
  font-weight: 500;
  font-size: var(--fs-medium);
  padding: 0 20px;
  height: 60px;
  border-radius: 40px;
  width: ${(p) => (p.stretch ? "100%" : "auto")};

  ${({ variant }) =>
    variant === "success"
      ? "background: var(--alert-success);"
      : variant === "secondary"
      ? "background: var(--secondary-link);"
      : "background: var(--primary);"};

  border: 1px solid transparent;
  color: var(--button-text-primary);
  cursor: pointer;
  align-self: center;
  &:active {
    filter: brightness(85%);
  }
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  ${(p) =>
    p.bare &&
    `
    background: none;
    border: 0;
    padding: 0;
    line-height: 0;
    box-shadow: none;
  `}

  & .progressBar {
    left: 13px;
    right: 13px;
  }
`;
