import countries from "i18n-iso-countries";
import enCountriesLocale from "i18n-iso-countries/langs/en.json";
import { useCallback } from "react";
import ReactCountryFlag from "react-country-flag";
import { Control, Controller, Path } from "react-hook-form";
import styled from "styled-components";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";

type Props<T extends { plateCountry?: string }> = {
  control: Control<T>;
  countryCodes: string[];
  invalid?: boolean;
};

countries.registerLocale(enCountriesLocale);

export const PlateCountryField = <T extends { plateCountry?: string }>({
  control,
  countryCodes,
  invalid,
}: Props<T>) => {
  const getDropdownContent = useCallback((code: string) => {
    const iso2code = countries.alpha3ToAlpha2(code)?.toUpperCase() || "";
    const countryName = (countries.getName(code, "en") || code).toUpperCase();

    return (
      <DropdownCountry>
        {!!code.length &&
          (iso2code.length ? (
            <ReactCountryFlag svg countryCode={iso2code} />
          ) : (
            <EmptyFlag />
          ))}
        <span>{countryName}</span>
      </DropdownCountry>
    );
  }, []);

  return (
    <Controller
      name={"plateCountry" as Path<T>}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <DropdownMenu
          placeholder={getDropdownContent(field?.value || "")}
          placeholderRef={field.ref}
          invalid={invalid}
          closeOnClick
        >
          {(countryCodes || []).map((code) => (
            <DropdownMenuItem onClick={() => field.onChange(code)} key={code}>
              {getDropdownContent(code)}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    />
  );
};

const DropdownCountry = styled.div`
  img {
    border: 1px solid #1a1a1a;
    border-radius: 5px;
    height: auto !important;
    width: 41px !important;
    min-height: 29px;
    margin-right: 14px;
  }
`;

const EmptyFlag = styled.div`
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #1a1a1a;
  border-radius: 5px;
  height: auto;
  width: 41px;
  min-height: 29px;
  margin-right: 14px;
`;
