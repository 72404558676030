import { Trans } from "@lingui/macro";
import { useHandleFinishedFn } from "common/hooks/useHandleFinishedFn";
import { VisitResponse } from "common/models/visits/types";
import { Pages } from "common/pages";
import { Footer } from "components/Footer";
import { IdleTimerBar } from "components/IdleTimerBar";
import { Status, VisitsContainer } from "components/PaymentStatus";
import { useHistory } from "react-router-dom";
import { Visits } from "views/visits/Visits";
import { Button } from "../components/Button";
import { Container, WidthContainer } from "../components/Container";
import { Content } from "../components/Content";
import { StatusIcon } from "../components/StatusIcon";
import { StickyBottom } from "../components/StickyBottom";

export const LeaveCompletePage = () => {
  const handleFinished = useHandleFinishedFn();

  const history = useHistory<VisitResponse | undefined>();
  const response = history.location.state;
  if (!response) {
    history.push(Pages.Home);
    return null;
  }

  return (
    <Container>
      <Content>
        <StatusIcon />
        <WidthContainer>
          <Status>
            <h3>
              <Trans>Your parking has been stopped</Trans>
            </h3>
          </Status>
          <VisitsContainer>
            <Visits visits={[response]} />
          </VisitsContainer>
        </WidthContainer>
        <StickyBottom>
          <Button variant="secondary" onClick={handleFinished}>
            <Trans>Okay</Trans>
            <IdleTimerBar />
          </Button>
        </StickyBottom>
      </Content>
      <Footer />
    </Container>
  );
};
