import { openApiUuid } from "../../common/types";
import {
  openApiPlateCountry,
  openApiPlateText,
} from "../../models/plates/types";

export enum IntegratedProviders {
  EasyPark = "easyPark",
  Parkkimaksu = "parkkimaksu",
  Invoice = "invoice",
}

export const IntegratedProviderNames: Record<IntegratedProviders, string> = {
  [IntegratedProviders.EasyPark]: "EasyPark",
  [IntegratedProviders.Parkkimaksu]: "Parkkimaksu",
  [IntegratedProviders.Invoice]: "Invoice",
};

export type ProviderInvite = {
  email: string;
  name: string;
};

export type ProviderDb = {
  id: string;
  name: string;
  publicKey?: string | null;
  notificationsUrl?: string | null;
  tokenGeneratedAt?: Date | null;
};

export type ProviderReference =
  | {
      providerId: string;
      integratedProvider?: null;
    }
  | {
      providerId?: null;
      integratedProvider: IntegratedProviders;
    };

export type NamedProviderReference =
  | {
      providerId: string;
      providerName: string;
      integratedProvider?: null;
    }
  | {
      providerId?: null;
      integratedProvider: IntegratedProviders;
    };

export type ProviderAssignment = {
  id: string;
  name: string;
  isIntegrated: boolean;
};

export type OperatorProvidersAssignmentInput = {
  providers: Omit<ProviderAssignment, "name">[];
};

export type OperatorProviderInput = {
  operatorId: string;
} & ProviderReference;

export type OperatorProviderDb = OperatorProviderInput & {
  id: number;
};

export type OperatorProvider = { id: number; name: string };

export type OperatorProvidersAssignment = {
  operatorId: string;
  providers: {
    id: string;
    name: string;
    isIntegrated: boolean;
  }[];
};

export type ProviderResponse = Omit<ProviderDb, "tokenGeneratedAt"> & {
  tokenGeneratedAt: string | null;
};

export type ProviderSettings = {
  notificationsUrl: string;
};

export const openApiProviderInvite = {
  type: "object",
  additionalProperties: false,
  required: ["email", "name"],
  properties: {
    email: {
      type: "string",
      format: "email",
      description: "Email to send invite to",
    },
    name: {
      type: "string",
      minLength: 1,
      maxLength: 255,
      description: "Name of the invited provider",
    },
  },
};

export const openApiProviderSettings = {
  type: "object",
  additionalProperties: false,
  required: ["notificationsUrl"],
  properties: {
    notificationsUrl: {
      type: "string",
      minLength: 0,
      maxLength: 2048,
      description: "Url to forward detections to",
    },
  },
};

export const openApiProviderAssignmentBase = {
  type: "object",
  required: ["id", "isIntegrated"],
  oneOf: [
    {
      properties: {
        id: openApiUuid,
        isIntegrated: { type: "boolean", enum: [false] },
      },
    },
    {
      properties: {
        id: { type: "string", enum: Object.values(IntegratedProviders) },
        isIntegrated: { type: "boolean", enum: [true] },
      },
    },
  ],
};

export const openApiProviderAssignment = {
  type: "object",
  required: [...openApiProviderAssignmentBase.required, "name"],
  oneOf: openApiProviderAssignmentBase.oneOf.map((d) => ({
    properties: {
      ...d.properties,
      name: { type: "string", description: "Name of the provider" },
    },
  })),
};

export const openApiOperatorProvider = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string", description: "Name of the provider" },
  },
};

export const openApiProvider = {
  type: "object",
  required: ["id", "name", "publicKey"],
  properties: {
    id: openApiUuid,
    name: {
      type: "string",
      description: "Name of the provider",
    },
    publicKey: {
      type: "string",
      nullable: true,
    },
    notificationsUrl: {
      type: "string",
      nullable: true,
    },
    tokenGeneratedAt: {
      type: "string",
      format: "date-time",
      nullable: true,
    },
  },
};

export const openApiProviderNotification = {
  type: "object",
  properties: {
    hallId: {
      type: "string",
      description:
        "Unique identifier for the parking area where vehicle was detected.",
    },
    visitId: {
      type: "string",
      nullable: true,
      description:
        "Unique identifier for the visit that is being notified about. null if there has been some error in detecting the visit start.",
    },
    plateText: {
      type: "string",
      description: "Detected plate text, excluding any dashes and spaces.",
    },
    plateCountry: {
      type: "string",
      description: "Plate country in the ISO 3166 Alpha-3 code format",
    },
    inDate: {
      type: "string",
      format: "date-time",
      nullable: true,
      description:
        "Visit start date in the ISO 8601 format. null if there has been some error in detecting the visit start.",
    },
    outDate: {
      type: "string",
      format: "date-time",
      nullable: true,
      description:
        "Visit end date in the ISO 8601 format. null if visit has not yet ended.",
    },
    price: {
      type: "number",
      nullable: true,
      description:
        "Visit price in cents. null if visit has not yet ended or it is not possible to determine price.",
    },
  },
};

export const openApiProviderManualStart = {
  type: "object",
  properties: {
    hallId: {
      type: "string",
      description:
        "Unique identifier for the parking area where the vehicle was detected.",
    },
    plateText: {
      ...openApiPlateText,
    },
    plateCountry: {
      ...openApiPlateCountry,
    },
  },
  required: ["hallId", "plateText", "plateCountry"],
};

export const openApiProviderManualStop = {
  type: "object",
  properties: {
    hallId: {
      type: "string",
      description:
        "Unique identifier for the parking area where the visit started.",
    },
    visitId: {
      type: "string",
      description:
        "Unique identifier for the visit that is about to be stopped.",
    },
    plateText: {
      ...openApiPlateText,
    },
    plateCountry: {
      ...openApiPlateCountry,
    },
    outDate: {
      type: "string",
      format: "date-time",
      description:
        "Visit end date in the ISO 8601 format, must be in the past and after the visit inDate.",
    },
  },
  required: ["hallId", "visitId", "plateText", "plateCountry", "outDate"],
};

export type ProviderManualStart = {
  hallId: string;
  plateText: string;
  plateCountry: string;
};

export type ProviderManualStop = {
  hallId: string;
  visitId: string;
  plateText: string;
  plateCountry: string;
  outDate: string;
};
