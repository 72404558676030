import client, { AxiosRequestConfig, terminalClient } from "api/client";
import {
  LeaveParkingInput,
  PaidVisitsResponse,
  UnpaidDataResponse,
} from "common/models/parkkimaksu/types";
import {
  PaymentReceiptText,
  ReceiptRequest,
} from "common/models/payments/types";
import { VisitResponse } from "common/models/visits/types";
import { Plate } from "common/types";
import {
  leaveHall,
  paidVisits,
  receipt,
  receipts,
  unpaidData,
} from "./apiPaths";

export const getUnpaidData = async (
  params: Plate,
  config: Partial<AxiosRequestConfig> = {}
) =>
  (
    await terminalClient.get<UnpaidDataResponse>(unpaidData, {
      ...config,
      params,
    })
  ).data;

export const getPaidVisits = async (paymentId: string) =>
  (await client.get<PaidVisitsResponse>(paidVisits, { params: { paymentId } }))
    .data;

export async function leaveParkingHall(payload: LeaveParkingInput) {
  const response = await terminalClient.post<VisitResponse>(leaveHall, payload);
  return response.data;
}

export async function requestReceipt(payload: ReceiptRequest) {
  return client.post(receipt, payload);
}

export async function getReceipts(paymentId: string[]) {
  const response = await client.get<PaymentReceiptText[]>(receipts, {
    params: { paymentId },
  });
  return response.data;
}
