import styled from "styled-components";

export const Label = styled.label`
  display: block;
  align-items: flex-start;
  justify-content: center;
  color: var(--text-primary);
  margin-top: 27px;
  margin-bottom: 12px;
  text-align: center;
`;
