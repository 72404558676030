import { getTerminalToken } from "common/localStorage";
import { Footer } from "components/Footer";
import { StripePaymentComplete } from "views/payments/StripePaymentComplete";
import { Container } from "../components/Container";
import { PaymentSuccess } from "../components/PaymentStatus";

export const PaymentCompletePage = () => {
  const terminalToken = getTerminalToken();

  return (
    <Container>
      {terminalToken ? (
        <PaymentSuccess allowReceiptRequest={true} />
      ) : (
        <StripePaymentComplete />
      )}
      <Footer />
    </Container>
  );
};
