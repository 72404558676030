import { ToastContainer } from "@asantech/common/react/ToastContainer/ToastContainer";
import "@asantech/common/styles/react-daterange-picker.css";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Pages } from "common/pages";
import { IdleTimeoutProvider } from "components/IdleTimeoutProvider";
import { useActivate } from "components/LanguageSelection";
import StripeProvider from "components/stripe/StripeProvider";
import { CustomerServicePage } from "pages/customerService";
import { DemoBotPage } from "pages/demoBot";
import { DoorPage } from "pages/door";
import { DoorQrPage } from "pages/doorQr";
import { LeaveCompletePage } from "pages/leaveComplete";
import { MainPage } from "pages/main";
import { PaymentPage } from "pages/paymentPage";
import { ReceiptRequestPage } from "pages/receiptRequest";
import { ReceiptsPage } from "pages/receipts";
import { SpacesPage } from "pages/spaces";
import { TerminalPage } from "pages/terminal";
import { UnpaidPage } from "pages/unpaid";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { AuthContextProvider } from "store/useAuth";
import { TerminalSettingsContextProvider } from "store/useTerminalSettings";
import { VisitsContextProvider } from "store/useVisits";
import Theme from "Theme";
import { history } from "./browserHistory";
import { PaymentCompletePage } from "./pages/paymentComplete";
import { PaymentFailedPage } from "./pages/paymentFailed";

const App = () => {
  useActivate();

  const nakedDomain = window.location.host.replace(/^www\./, "");

  if (nakedDomain !== window.location.host) {
    window.location.host = nakedDomain;
  }

  return (
    <Theme>
      <I18nProvider i18n={i18n}>
        <ToastContainer
          hideProgressBar
          closeButton={false}
          position="bottom-center"
        />
        <Router history={history}>
          <Switch>
            <Route path={Pages.Terminal}>
              <TerminalPage />
            </Route>
            <AuthContextProvider>
              <IdleTimeoutProvider>
                <TerminalSettingsContextProvider>
                  <Switch>
                    <Route
                      path={[
                        Pages.Home,
                        Pages.Unpaid,
                        Pages.Payment,
                        Pages.PaymentComplete,
                        Pages.PaymentFailure,
                        Pages.LeaveComplete,
                        Pages.ReceiptRequest,
                      ]}
                      exact
                    >
                      <VisitsContextProvider>
                        <Route path={Pages.Home} exact>
                          <MainPage />
                        </Route>
                        <StripeProvider>
                          <Route path={Pages.Unpaid}>
                            <UnpaidPage />
                          </Route>
                          <Route path={Pages.Payment}>
                            <PaymentPage />
                          </Route>
                          <Route path={Pages.PaymentComplete}>
                            <PaymentCompletePage />
                          </Route>
                          <Route path={Pages.PaymentFailure}>
                            <PaymentFailedPage />
                          </Route>
                        </StripeProvider>
                        <Route path={Pages.LeaveComplete}>
                          <LeaveCompletePage />
                        </Route>
                        <Route path={Pages.ReceiptRequest} exact>
                          <ReceiptRequestPage />
                        </Route>
                      </VisitsContextProvider>
                    </Route>
                    <Route path={Pages.Receipts} exact>
                      <ReceiptsPage />
                    </Route>
                    <Route path={Pages.CustomerService}>
                      <CustomerServicePage />
                    </Route>
                    <Route path={Pages.DemoBot}>
                      <DemoBotPage />
                    </Route>
                    <Route path={`${Pages.Door}/:doorPath`} exact>
                      <DoorPage />
                    </Route>
                    <Route path={`${Pages.Door}/:doorPath/qr`} exact>
                      <DoorQrPage />
                    </Route>
                    <Route path={`${Pages.Space}/:plate?`} exact>
                      <SpacesPage />
                    </Route>
                    <Route>
                      <Redirect to={Pages.Home} />
                    </Route>
                  </Switch>
                </TerminalSettingsContextProvider>
              </IdleTimeoutProvider>
            </AuthContextProvider>
          </Switch>
        </Router>
      </I18nProvider>
    </Theme>
  );
};

export default App;
