import { Button } from "components/Button";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../assets/svgs/arrow-icon.svg";

export const BackButton = ({
  onClick,
}: Pick<ButtonHTMLAttributes<HTMLButtonElement>, "onClick">) => (
  <Button bare onClick={onClick}>
    <StyledArrowIcon />
  </Button>
);

const StyledArrowIcon = styled(ArrowIcon)`
  width: 40px;
  height: 40px;
  fill: var(--text-primary);
`;
