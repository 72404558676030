import { Loader } from "@asantech/common/react/Loader";
import styled from "styled-components";

export const ButtonLoader = () => (
  <LoaderWrapper>
    <Loader size={40} />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
