import styled from "styled-components";

import { isTerminal } from "common/utils";
import { useTerminalSettings } from "store/useTerminalSettings";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { WidthContainer } from "./Container";
import { LanguageSelection } from "./LanguageSelection";

export const Header: React.FC = () => {
  const { settings } = useTerminalSettings();

  return (
    <HeaderElement paddingHeader={!!settings?.brandingImgUrl}>
      {!settings?.brandingImgUrl && (
        <HeaderContainer>
          <Left>
            {/* <StyledMenuIcon /> */}
            <TextBox>{!isTerminal && <LanguageSelection />}</TextBox>
          </Left>

          <Center>
            <StyledLogo />
            <HeaderName>
              Parkkimaksu<TextSecondary>.</TextSecondary>fi
            </HeaderName>{" "}
          </Center>

          <Right>
            {/* <TextBox>
            <HeaderText>Login</HeaderText>
          </TextBox>
          <TextBox>
            <HeaderText>Sign Up</HeaderText>
          </TextBox> */}
          </Right>
        </HeaderContainer>
      )}
    </HeaderElement>
  );
};
const Left = styled.div`
  justify-self: left;
  display: flex;
  align-content: center;
`;
const Center = styled.div`
  justify-self: center;
  display: flex;
`;
const Right = styled.div`
  justify-self: right;
  display: flex;
`;

const HeaderElement = styled.div<{ paddingHeader?: boolean }>`
  padding: var(--margin) var(--margin) 0;
  ${({ paddingHeader }) => paddingHeader && "padding-top: 200px"};
`;

const HeaderContainer = styled(WidthContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: var(--margin) 0;
`;

const HeaderName = styled.div`
  padding-left: 20px;
  font-size: var(--fs-big);
  font-family: var(--font-logo);
`;

const StyledLogo = styled(Logo)`
  width: 40px;
  height: 40px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
`;
const TextSecondary = styled.span`
  color: var(--primary);
`;
