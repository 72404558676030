import { Loader } from "@asantech/common/react/Loader";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { AsyncState, useAsync } from "@react-hookz/web";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { getTerminalToken } from "common/localStorage";
import { StripeCreateIntentResponse } from "common/models/payments/types";
import { Pages } from "common/pages";
import {
  CLIENT_SECRET_SEARCH_PARAM,
  getUrlClientSecret,
  PAYMENT_ID_SEARCH_PARAM,
} from "common/stripe";
import { SupportedLanguage } from "components/LanguageSelection";
import { SUPPORT_EMAIL } from "config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { PAYMENT_FINISHED_STATUS } from "../../../../backend/src/models/payments/types";
import { createStripePaymentIntent } from "../../api/payments";
import { useVisits } from "../../store/useVisits";
import { EmailLink } from "../EmailLink";
import { getStripe } from "./stripeClient";

interface Props {
  children: React.ReactNode;
}
type StripeContextType = {
  clientSecret: string;
  createPaymentIntentStatus: AsyncState<
    StripeCreateIntentResponse | undefined
  >["status"];
  createPaymentIntent: () => Promise<StripeCreateIntentResponse | undefined>;
};

export const StripeContext = React.createContext<StripeContextType | undefined>(
  undefined
);

export default function StripeProvider({ children }: Props) {
  const [stripeClient, setStripeClient] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const { unpaidData, setPaymentError, setPaymentIntentPrice } = useVisits();
  const history = useHistory();
  const terminalToken = getTerminalToken();
  const theme = useTheme();

  const handleError = useCallback(
    (error: unknown) => {
      console.error(error);
      setPaymentError(
        <h3>
          <Trans>
            Failed to start payment. Please try again later or contact our
            support at <EmailLink email={SUPPORT_EMAIL} />
          </Trans>
        </h3>
      );
      history.push(Pages.PaymentFailure);
    },
    [setPaymentError, history]
  );

  const loadStripeClient = useCallback(async () => {
    try {
      const client = await getStripe();
      setStripeClient(client);
    } catch (error) {
      handleError(error);
    }
  }, [handleError]);

  useEffect(() => {
    if (!terminalToken) {
      setClientSecret(getUrlClientSecret() || "");
      loadStripeClient();
    }
  }, [loadStripeClient, terminalToken]);

  const { i18n } = useLingui();
  const [
    { status: createPaymentIntentStatus },
    { execute: createPaymentIntent },
  ] = useAsync(async () => {
    try {
      if (!stripeClient || !unpaidData.visitIds.length) return;
      const result = await createStripePaymentIntent(unpaidData.visitIds);
      const {
        paymentId,
        clientSecret: clientSecretResponse = "",
        status,
        paymentIntentPrice,
      } = result;

      setClientSecret(clientSecretResponse);
      setPaymentIntentPrice(paymentIntentPrice);
      history.push({
        pathname:
          status === PAYMENT_FINISHED_STATUS
            ? Pages.PaymentComplete
            : Pages.Payment,
        search: new URLSearchParams({
          [CLIENT_SECRET_SEARCH_PARAM]: clientSecretResponse,
          [PAYMENT_ID_SEARCH_PARAM]: paymentId,
        }).toString(),
      });
      return result;
    } catch (error) {
      handleError(error);
    }
  });

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      theme: (theme as any).stripeTheme || "night",
    },
    locale: i18n.locale as SupportedLanguage,
  };
  const value = {
    clientSecret,
    createPaymentIntentStatus,
    createPaymentIntent,
  };

  if (!terminalToken && !stripeClient) {
    return <Loader />;
  }

  if (terminalToken || !clientSecret) {
    return (
      <StripeContext.Provider value={value}>{children}</StripeContext.Provider>
    );
  }

  return (
    <StripeContext.Provider value={value}>
      <Elements key={clientSecret} options={options} stripe={stripeClient}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
}

export const useStripeProvider = () => {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error(
      "useStripeProvider must be used within StripeContextProvider"
    );
  }
  return context;
};
