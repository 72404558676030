import { useMaskedHookFormInput } from "@asantech/common/react/common/hooks/useMaskedHookFormInput";
import { normalizePlateText } from "@asantech/common/react/common/plateTextAdapters";
import { Trans } from "@lingui/macro";
import { getDoorByPath, openDoorByPath } from "api/doors";
import { DoorDb } from "common/models/doors/types";
import { Plate } from "common/types";
import { Button } from "components/Button";
import {
  Container,
  WidthContainer,
  WidthContainerNarrow,
} from "components/Container";
import { Content } from "components/Content";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Label } from "components/Label";
import { PlateTextField } from "components/PlateTextField";
import { StatusIcon } from "components/StatusIcon";
import countries from "i18n-iso-countries";
import enCountriesLocale from "i18n-iso-countries/langs/en.json";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
type FormData = Plate;
countries.registerLocale(enCountriesLocale);
export const DoorPage = () => {
  const { doorPath } = useParams<{ doorPath: string }>();
  const [door, setDoor] = useState<DoorDb | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDoorByPath(doorPath);
        setDoor(response);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [doorPath]);
  const plate: Plate = {
    plateText: "",
  };
  const [loading, setLoading] = useState(false);
  const { formState, register, handleSubmit, reset } = useForm<FormData>({
    mode: "all",
    defaultValues: plate,
  });
  const [doorOpened, setDoorOpened] = useState(false);
  const handleOpenClick = async (formData: FormData) => {
    setLoading(true);
    try {
      const result = await openDoorByPath(doorPath, formData.plateText);
      if (result) {
        setDoorOpened(result);
        setTimeout(() => {
          reset();
          setDoorOpened(false);
        }, 5000);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const textRegister = useMaskedHookFormInput(normalizePlateText, register);

  return (
    <ContainerStyled>
      <Header />
      <Form onSubmit={handleSubmit(handleOpenClick)}>
        <ContentStyled>
          <WidthContainer>
            <Label>
              <Trans>Door Name:</Trans> <br />
              {door?.name ? door.name : "No such door"}
              <br />
            </Label>
          </WidthContainer>
          <WidthContainerNarrow>
            <PlateTextField register={textRegister} formState={formState} />
          </WidthContainerNarrow>
          <WidthContainerNarrow>
            <ContinueButton
              onClick={handleSubmit(handleOpenClick)}
              loading={loading}
              stretch
            >
              <Trans>Open</Trans>
            </ContinueButton>
            {doorOpened && <StatusIcon icon="ok" />}
          </WidthContainerNarrow>
        </ContentStyled>
      </Form>
      <Footer />
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  min-height: 100%;
  overflow: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ContentStyled = styled(Content)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  overflow: initial;
`;

const ContinueButton = styled(Button)`
  margin-top: 40px;
`;
