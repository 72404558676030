import { withRetry } from "@asantech/common/common/requestUtils";
import { useAsync } from "@react-hookz/web";
import {
  debugSetPaidVisits,
  getNetsPaymentStatus,
  startNetsPayment,
} from "api/payments";
import { getDebugTerminalPayment, getTerminalToken } from "common/localStorage";
import { Pages } from "common/pages";
import { PAYMENT_ID_SEARCH_PARAM } from "common/stripe";
import { parseToken } from "common/utils";
import { useStripeProvider } from "components/stripe/StripeProvider";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useVisits } from "../../store/useVisits";

const pollNetsPaymentStatus = withRetry(
  async () => {
    const status = await getNetsPaymentStatus();
    if (status.isInProgress) throw new Error("Payment timed out");
    return status;
  },
  1000,
  305
);

const useNetsPayment = () => {
  const history = useHistory();
  const { unpaidData, setTerminalRequest, terminalRequest } = useVisits();
  const terminalToken = getTerminalToken();
  const [{ status }, { execute }] = useAsync(async () => {
    try {
      const { terminalId = "" } = parseToken(terminalToken);
      const request = startNetsPayment({
        terminalId,
        visits: unpaidData.visitIds,
      });

      setTerminalRequest(request);
      const { paymentId = "" } = await request;
      const status = await pollNetsPaymentStatus();
      if (status.error) throw new Error(status.error);
      setTerminalRequest(undefined);
      history.push({
        pathname: Pages.PaymentComplete,
        search: new URLSearchParams({
          [PAYMENT_ID_SEARCH_PARAM]: paymentId,
        }).toString(),
      });
    } catch (error) {
      console.error(error);
      setTerminalRequest(undefined);
      history.push(Pages.PaymentFailure);
    }
  });

  useEffect(() => {
    if (terminalRequest) history.push(Pages.Payment);
  }, [terminalRequest, history]);

  return {
    status,
    execute,
  };
};

const useDebugPayment = () => {
  const history = useHistory();
  const { unpaidData } = useVisits();
  const [{ status }, { execute }] = useAsync(async () => {
    try {
      const { paymentId = "" } = await debugSetPaidVisits(unpaidData.visitIds);
      history.push({
        pathname: Pages.PaymentComplete,
        search: new URLSearchParams({
          [PAYMENT_ID_SEARCH_PARAM]: paymentId,
        }).toString(),
      });
    } catch (error) {
      console.error(error);
      history.push(Pages.PaymentFailure);
    }
  });
  return {
    status,
    execute,
  };
};

export const usePayment = () => {
  const terminalToken = getTerminalToken();
  const debugTerminalPayment = getDebugTerminalPayment();
  const { createPaymentIntentStatus, createPaymentIntent } =
    useStripeProvider();
  const netsPayment = useNetsPayment();
  const debugPayment = useDebugPayment();

  return terminalToken
    ? debugTerminalPayment
      ? debugPayment
      : netsPayment
    : {
        status: createPaymentIntentStatus,
        execute: createPaymentIntent,
      };
};
