import { Loader } from "@asantech/common/react/Loader";
import { Pages } from "common/pages";
import DOMPurify from "dompurify";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ChatbotIcon from "../../../assets/images/chatbot-icon.png";
import UserIcon from "../../../assets/images/user-icon.png";
import { ReactComponent as CloseIcon } from "../../../assets/svgs/close-icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/svgs/send-icon.svg";

type Message = {
  sender: string;
  content: string;
};

type ChatbotProps = {
  messages: Message[];
  loading: boolean;
  onSend?: (message: string) => void;
};

export const Chatbot: React.FC<ChatbotProps> = ({
  messages,
  loading,
  onSend,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (onSend && newMessage.trim() !== "") {
      onSend(newMessage);
      setNewMessage("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleKeyboardOpen = () => {
      scrollToBottom();
    };
    window.addEventListener("resize", handleKeyboardOpen);
    return () => {
      window.removeEventListener("resize", handleKeyboardOpen);
    };
  }, []);

  return (
    <Content>
      <HeaderWrapper>
        <HeaderTitleContainer>
          <HeaderIcon src={ChatbotIcon} alt="Icon" />
          <div>
            <HeaderName>Parkkimaksu Bot</HeaderName>
            <HeaderSubName>CHATBOT</HeaderSubName>
          </div>
        </HeaderTitleContainer>
        <Link to={Pages.Home}>
          <StyledCloseIcon />
        </Link>
      </HeaderWrapper>
      <MessagesWrapper ref={messagesEndRef}>
        {messages.map((message, i) => (
          <Fragment key={i}>
            {message.sender === "Parkkimaksu Bot" ? (
              <BotMessageContainer>
                <Sender sender={message.sender} />
                <MessageContent
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(message.content, {
                      USE_PROFILES: { html: true },
                    }),
                  }}
                />
              </BotMessageContainer>
            ) : (
              <UserMessageContainer>
                <Sender sender={message.sender} />
                <MessageContent>{message.content}</MessageContent>
              </UserMessageContainer>
            )}
          </Fragment>
        ))}
      </MessagesWrapper>

      <FooterWrapper>
        <FooterInputContainer>
          <FooterInput
            type="text"
            value={newMessage}
            placeholder="Message Parkkimaksu Bot..."
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <FooterButton onClick={sendMessage} disabled={!onSend}>
            {loading ? <StyledLoader /> : <StyledSendIcon />}
          </FooterButton>
        </FooterInputContainer>
      </FooterWrapper>
    </Content>
  );
};

const Sender = ({ sender }: { sender: Message["sender"] }) => (
  <UserContainer>
    {sender === "Parkkimaksu Bot" ? (
      <>
        <StyledUserIcon src={ChatbotIcon} alt="Bot" />
        <Username>{sender}</Username>
      </>
    ) : (
      <>
        <Username>{sender}</Username>
        <StyledUserIcon src={UserIcon} alt="User" />
      </>
    )}
  </UserContainer>
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 500px;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--chatbot-header-background);
  padding: 10px;
  @media (max-height: 700px) {
    display: none;
  }
`;

const HeaderTitleContainer = styled.div`
  display: flex;
`;

const HeaderIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

const HeaderName = styled.div`
  font-weight: bold;
  text-wrap: nowrap;
`;

const HeaderSubName = styled.div`
  font-size: var(--fs-small);
  color: var(--chatbot-header-sub-text);
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 12px;
  length: 12px;
  margin: 15px;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--chatbot-background);
  overflow-y: scroll;
  height: calc(100% - 100px);
  padding-top: 15px;
`;

const MessageContainer = styled.div`
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  left: 25px;
  width: 90%;
`;

const UserContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const StyledUserIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
  @media (max-height: 700px) {
    width: 20px;
    height: 20px;
  }
`;

const Username = styled.div`
  font-size: var(--fs-smallish);
  margin-bottom: 15px;
  @media (max-height: 700px) {
    font-size: var(--fs-small);
  }
`;

const MessageContent = styled.div`
  max-width: 90%;
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 20px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  font-size: var(--fs-small);
  white-space: pre-line;
  @media (max-height: 700px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const BotMessageContainer = styled(MessageContainer)`
  text-align: left;

  ${MessageContent} {
    background-color: var(--chat-user-box-bot);
    color: var(--text-primary);
    text-align: left;
  }

  ${UserContainer} {
    justify-content: flex-start;
  }
`;

const UserMessageContainer = styled(MessageContainer)`
  text-align: right;

  ${MessageContent} {
    background-color: var(--chat-user-box-user);
    color: var(--chat-user-text);
    text-align: right;
  }

  ${UserContainer} {
    justify-content: flex-end;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  justify-content: center;
  background-color: var(--chatbot-footer-background);
  @media (max-height: 700px) {
    padding: 0px;
  }
`;

const FooterInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid;
  border-color: var(--chatbot-header-background);
  border-radius: 15px;
  margin: 5px;
  padding: 5px;
  width: 94%;
  background-color: var(--chatbot-footer-background);
  @media (max-height: 700px) {
    padding: 3px;
  }
`;

const FooterInput = styled.input`
  padding: 10px;
  width: 80%;
  font-size: var(--fs-small);
  color: var(--text-primary);
  background-color: var(--chatbot-footer-background);
  border: none;
  outline: none;
`;

const FooterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px;
  background-color: var(--chatbot-footer-button);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: var(--chatbot-footer-button-hover);
  }
`;

const StyledSendIcon = styled(SendIcon)`
  width: 20px;
  height: 20px;
  @media (max-height: 700px) {
    width: 15px;
    height: 15px;
  }
`;

const StyledLoader = styled(Loader)`
  width: 20px;
  height: 20px;
  @media (max-height: 700px) {
    width: 15px;
    height: 15px;
  }
`;
