import { format } from "date-fns";
import { getTerminalToken } from "./localStorage";

const DATE_FROM_EPOCH_MULTIPLIER = 1000;

type DecodedTokenBody = {
  exp: number;
  terminalId?: string;
};

export const expirationDateInSeconds = (exp: number) =>
  exp * DATE_FROM_EPOCH_MULTIPLIER - Date.now();

export const tokenExpired = (exp: number) => expirationDateInSeconds(exp) < 0;

export const getErrorMessage = (error: unknown) =>
  error instanceof Error ? error.message : String(error);

export const parseToken = (token: string | null): DecodedTokenBody => {
  try {
    return JSON.parse(atob(token?.split(".")[1] || ""));
  } catch (e) {
    throw new Error("Error parsing token " + e);
  }
};

export const noop = () => undefined;

export const emptyToString = (value: string | undefined | null) => value || "";

export const formatDateTime = (dt?: Date | string | null) => {
  if (!dt) return "";
  const date = typeof dt === "string" ? new Date(dt) : dt;
  return format(date, "yyyy-MM-dd HH:mm");
};

export const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

export const isTerminal = !!getTerminalToken();

export const docModified = async () => {
  const response = await fetch("/");
  const remoteDocument = await response.text();
  const tagMatch = /<script.*\/(main.*\.js)/.exec(remoteDocument);
  const remoteMainScript = tagMatch && tagMatch[1];
  if (!remoteMainScript) return;

  const scripts = document.head.getElementsByTagName("script");
  for (const s of Array.from(scripts)) {
    const fileMatch = /main.*\.js/.exec(s.src);
    if (!fileMatch) continue;
    const localMainScript = fileMatch[0];
    return remoteMainScript !== localMainScript;
  }
};
