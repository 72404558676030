import { useAsync } from "@react-hookz/web";
import { handleError } from "api/client";
import { leaveParkingHall } from "api/visits";
import { Pages } from "common/pages";
import { useHistory } from "react-router-dom";
import { useVisits } from "../../store/useVisits";

export const useLeave = () => {
  const history = useHistory();
  const { unpaidData } = useVisits();

  const [{ status }, { execute }] = useAsync(async () => {
    const pendingVisitIds = unpaidData.pendingVisitIds;
    try {
      if (pendingVisitIds[0]) {
        const result = await leaveParkingHall({
          visitId: pendingVisitIds[0],
        });
        history.push(Pages.LeaveComplete, result);
      }
    } catch (error) {
      handleError(error, "Error stopping parking.");
    }
  });
  return {
    execute,
    status,
  };
};
