import { Loader } from "@asantech/common/react/Loader";
import { useAsync } from "@react-hookz/web";
import { getNetsTerminalSettings } from "api/terminalSettings";
import { NetsTerminal } from "common/models/terminals/types";
import { ChildrenOnly } from "common/types";
import { isTerminal } from "common/utils";
import React, { useContext, useEffect } from "react";
import { createGlobalStyle } from "styled-components";

type TerminalSettingsContextType = {
  loading: boolean;
  error?: Error;
  settings: NetsTerminal | undefined;
};

export const TerminalSettingsContext = React.createContext<
  TerminalSettingsContextType | undefined
>(undefined);

export const TerminalSettingsContextProvider = ({ children }: ChildrenOnly) => {
  const [
    { status, error, result: settings },
    { execute: fetchTerminalSettings },
  ] = useAsync(getNetsTerminalSettings);

  useEffect(() => {
    if (isTerminal) fetchTerminalSettings();
  }, [fetchTerminalSettings]);

  const value = {
    loading: status === "loading",
    error,
    settings,
  };

  if (
    (isTerminal && status === "not-executed") ||
    value.loading ||
    value.error
  ) {
    return <Loader />;
  }

  return (
    <TerminalSettingsContext.Provider value={value}>
      {children}
      {isTerminal && <TerminalGlobalStyle />}
    </TerminalSettingsContext.Provider>
  );
};

export const useTerminalSettings = () => {
  const context = useContext(TerminalSettingsContext);
  if (context === undefined)
    throw new Error("useSettings must be used within SettingsContextProvider");
  return context;
};

const TerminalGlobalStyle = createGlobalStyle`
* {
  -webkit-user-select: none;
  user-select: none;
}
`;
