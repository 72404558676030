import {
  DropdownMenuItem as DropdownMenuItemOrig,
  DropdownMenu as DropdownMenuOrig,
} from "@asantech/common/react/DropdownMenu";

import styled from "styled-components";

export const DropdownMenu = styled(DropdownMenuOrig)`
  width: 100%;

  .DropdownMenu__Placeholder {
    padding-right: 50px;
    font-size: 30px;
    background-color: var(--input-bg);
    border-radius: 5px;
    height: 60px;
    vertical-align: middle;
    color: var(--text-primary);
  }

  .DropdownMenu__Content {
    background-color: var(--input-bg);
    border-radius: 5px;
    top: 60px;
  }

  svg.icon {
    color: var(--text-primary);
    width: 12px;
    height: 20px;
    right: 20px;
    margin-top: -9px;
  }
`;

export const DropdownMenuItem = styled(DropdownMenuItemOrig)`
  && {
    font-size: 30px;
    color: var(--text-primary);
  }

  &:hover {
    background: var(--primary);
    color: var(--text-primary);
  }
`;
