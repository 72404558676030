import { useAsync, useSessionStorageValue } from "@react-hookz/web";
import { getUnpaidData } from "api/visits";
import axios, { CancelTokenSource } from "axios";
import { UnpaidDataResponse } from "common/models/parkkimaksu/types";
import { NetsPaymentResponse } from "common/models/payments/types";
import { Pages } from "common/pages";
import { ChildrenOnly, Plate } from "common/types";
import { useIdleTimeout } from "components/IdleTimeoutProvider";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

type VisitsContextType = {
  loading: boolean;
  isInitial: boolean;
  error?: Error;
  unpaidData: UnpaidDataResponse;
  plate?: Plate;
  resetPlate: () => void;
  refreshUnpaidData: (plate?: Plate) => Promise<UnpaidDataResponse | undefined>;
  paymentError?: ReactNode;
  setPaymentError: (message?: ReactNode) => void;
  totalPrice: number;
  setPaymentIntentPrice: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  terminalRequest: Promise<NetsPaymentResponse> | undefined;
  setTerminalRequest: React.Dispatch<
    React.SetStateAction<Promise<NetsPaymentResponse> | undefined>
  >;
};

type RequestMeta = {
  axiosSource?: CancelTokenSource;
};

type UnpaidResults = {
  data?: UnpaidDataResponse;
  fetchedAt: Date;
};

const PLATE_INFO_SESSION_KEY = "unpaid-plate-info";
const defaultUnpaidData = {
  visitIds: [],
  price: 0,
  pendingVisitIds: [],
  freeParkingEnd: null,
  countries: [],
  feePerVisit: 0,
  tax: 0,
};

const useGoHomeIfPlateNeeded = (
  unpaidData: UnpaidDataResponse | undefined,
  plate: Plate | undefined
) => {
  const history = useHistory();
  const countries = unpaidData?.countries?.length || 0;
  useEffect(() => {
    if (history.location.pathname === Pages.Home) return;
    if (countries > 1 || !plate?.plateText) history.push(Pages.Home);
  }, [history, plate?.plateText, countries]);
};

export const VisitsContext = React.createContext<VisitsContextType | undefined>(
  undefined
);

export const VisitsContextProvider = ({ children }: ChildrenOnly) => {
  const [unpaidResults, setUnpaidResults] = useState<UnpaidResults>();
  const [terminalRequest, setTerminalRequest] =
    useState<Promise<NetsPaymentResponse>>();
  const [paymentIntentPrice, setPaymentIntentPrice] = useState<number>();
  const { lastTimedOutAt } = useIdleTimeout();

  const { value: plate, set: setPlate } = useSessionStorageValue<Plate>(
    PLATE_INFO_SESSION_KEY,
    {
      defaultValue: { plateText: "" },
    }
  );

  const [paymentError, setPaymentError] = useState<ReactNode>();

  const requestRef = useRef<RequestMeta>({});

  const [{ status, error }, { execute: fetchUnpaidData }] = useAsync(
    async (plate: Plate) => {
      try {
        const requestMeta = requestRef.current;
        if (requestMeta.axiosSource) requestMeta.axiosSource.cancel();
        requestMeta.axiosSource = axios.CancelToken.source();

        const data = await getUnpaidData(plate, {
          cancelToken: requestMeta.axiosSource.token,
        });
        requestRef.current = {
          axiosSource: undefined,
        };

        setUnpaidResults({ fetchedAt: new Date(), data });
        if (data.countries.length === 1 && !plate.plateCountry) {
          setPlate({
            ...plate,
            plateCountry: data.countries[0],
          });
        }
        return data;
      } catch (err) {
        if (axios.isCancel(err)) return;
        console.log(err);
        setUnpaidResults({ fetchedAt: new Date(), data: undefined });
      }
    }
  );

  const resetUnpaidData = useCallback(() => {
    if (requestRef.current.axiosSource) requestRef.current.axiosSource.cancel();
    setUnpaidResults(undefined);
    setPaymentIntentPrice(undefined);
  }, []);

  const resetPlate = useCallback(async () => {
    setPlate({ plateText: "" });
    resetUnpaidData();
  }, [setPlate, resetUnpaidData]);

  useGoHomeIfPlateNeeded(unpaidResults?.data, plate);

  const refreshUnpaidData = useCallback(
    async (newPlate?: Plate) => {
      if (
        !newPlate &&
        (status === "loading" ||
          new Date().getTime() - (unpaidResults?.fetchedAt?.getTime() || 0) <
            1000)
      )
        return;

      if (newPlate) setPlate(newPlate);
      const target = newPlate || (plate?.plateText ? plate : undefined);
      if (target) {
        return fetchUnpaidData(target);
      } else {
        resetUnpaidData();
      }
    },
    [
      fetchUnpaidData,
      plate,
      status,
      resetUnpaidData,
      setPlate,
      unpaidResults?.fetchedAt,
    ]
  );

  useEffect(() => {
    if (!unpaidResults?.fetchedAt) refreshUnpaidData();
  }, [refreshUnpaidData, unpaidResults?.fetchedAt]);

  useEffect(() => {
    if (
      paymentIntentPrice !== undefined &&
      unpaidResults?.data?.price !== paymentIntentPrice
    ) {
      refreshUnpaidData();
    }
  }, [paymentIntentPrice, refreshUnpaidData, unpaidResults?.data?.price]);

  useEffect(() => {
    if (lastTimedOutAt) resetPlate();
  }, [resetPlate, lastTimedOutAt]);

  const value = useMemo(
    () => ({
      loading: status === "loading",
      isInitial: status === "not-executed",
      error: error,
      unpaidData: unpaidResults?.data || defaultUnpaidData,
      plate,
      resetPlate,
      paymentError,
      setPaymentError,
      totalPrice:
        paymentIntentPrice !== undefined
          ? paymentIntentPrice
          : (unpaidResults?.data || defaultUnpaidData).price,
      setPaymentIntentPrice,
      terminalRequest,
      setTerminalRequest,
      refreshUnpaidData,
    }),
    [
      error,
      paymentError,
      paymentIntentPrice,
      plate,
      resetPlate,
      status,
      terminalRequest,
      unpaidResults?.data,
      refreshUnpaidData,
    ]
  );

  return (
    <VisitsContext.Provider value={value}>{children}</VisitsContext.Provider>
  );
};

export const useVisits = () => {
  const context = useContext(VisitsContext);
  if (context === undefined)
    throw new Error("useVisits must be used within VisitsContextProvider");
  return context;
};
