import { Trans } from "@lingui/macro";
import { getDoorByPath } from "api/doors";
import { DoorDb } from "common/models/doors/types";
import { Pages } from "common/pages";
import { Container, WidthContainer } from "components/Container";
import { Content } from "components/Content";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import { Label } from "components/Label";
import countries from "i18n-iso-countries";
import enCountriesLocale from "i18n-iso-countries/langs/en.json";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import styled from "styled-components";

countries.registerLocale(enCountriesLocale);
export const DoorQrPage = () => {
  const { doorPath } = useParams<{ doorPath: string }>();
  const [door, setDoor] = useState<DoorDb | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDoorByPath(doorPath);
        setDoor(response);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [doorPath]);
  return (
    <ContainerStyled>
      <HeaderContainer>
        <Header />
      </HeaderContainer>

      <ContentStyled>
        <WidthContainer>
          <Label>
            <Trans>Door Name:</Trans> <br />
            {door?.name || "No such door"}
            <br />
          </Label>
        </WidthContainer>

        {door?.name && (
          <WidthContainer>
            <Label>
              QR: <br />
              <StyledQrCode
                value={`${window.location.origin}${Pages.Door}/${door.path}`}
                size={512}
              />
            </Label>
          </WidthContainer>
        )}
        <br />
      </ContentStyled>
      <BrandAwareFooter />
    </ContainerStyled>
  );
};

const StyledQrCode = styled(QRCode)`
  margin: 40px;
  outline: 20px solid #fff;
`;

const HeaderContainer = styled(WidthContainer)`
  margin-bottom: 30px;
`;

const ContainerStyled = styled(Container)`
  min-height: 100%;
  overflow: auto;
`;

const ContentStyled = styled(Content)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  overflow: initial;
`;
