import { useStripe } from "@stripe/react-stripe-js";
import { getUrlClientSecret } from "common/stripe";
import { PageLoader } from "components/PageLoader";
import { useEffect, useState } from "react";
import { PAYMENT_FINISHED_STATUS } from "../../../../backend/src/models/payments/types";
import { PaymentFailure, PaymentSuccess } from "../../components/PaymentStatus";

export const StripePaymentComplete = () => {
  const stripe = useStripe();
  const [status, setStatus] = useState<string>();

  useEffect(() => {
    if (!stripe) return;

    const clientSecret = getUrlClientSecret();

    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent?.status) setStatus(paymentIntent?.status);
    });
  }, [stripe]);

  if (!status) return <PageLoader />;

  return status === PAYMENT_FINISHED_STATUS ? (
    <PaymentSuccess />
  ) : (
    <PaymentFailure />
  );
};
