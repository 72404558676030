import { Loader } from "@asantech/common/react/Loader";
import { Trans } from "@lingui/macro";
import { useMountEffect } from "@react-hookz/web";
import { useHandleFinishedFn } from "common/hooks/useHandleFinishedFn";
import { useLeave } from "common/hooks/useLeave";
import { usePayment } from "common/hooks/usePayment";
import { isTerminal } from "common/utils";
import { Alert } from "components/Alert";
import { Button } from "components/Button";
import {
  Container,
  WidthContainer,
  WidthContainerNarrow,
} from "components/Container";
import { Content } from "components/Content";
import { Euros } from "components/Euros";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import { IdleTimerBar } from "components/IdleTimerBar";
import { StatusIcon } from "components/StatusIcon";
import styled from "styled-components";
import { FreeRemaining } from "views/unpaid/components/FreeRemaining";
import { PlateTextField } from "views/unpaid/components/PlateTextField";
import { ReactComponent as KeyboardIcon } from "../assets/svgs/keyboard.svg";
import { ReactComponent as ParkingIcon } from "../assets/svgs/parking.svg";
import { ReactComponent as ReceiptIcon } from "../assets/svgs/receipt.svg";
import { ReactComponent as StripeIcon } from "../assets/svgs/stripe-logo.svg";
import { useVisits } from "../store/useVisits";

export const UnpaidPage: React.FC = () => {
  const {
    loading: visitsLoading,
    isInitial,
    error,
    unpaidData,
    refreshUnpaidData,
  } = useVisits();

  useMountEffect(() => {
    refreshUnpaidData();
  });
  const { status: paymentStatus, execute: executePayment } = usePayment();
  const { status: leaveStatus, execute: executeLeave } = useLeave();
  const handleFinished = useHandleFinishedFn();
  const { visitIds, feePerVisit, freeParkingEnd, price } = unpaidData;
  const hasUnpaid = !!visitIds.length;
  const hasPending = !!freeParkingEnd;
  const hasResults = hasUnpaid || hasPending;
  const loading = visitsLoading || isInitial;

  return (
    <Container>
      <Header />
      <Content>
        <WidthContainerNarrow>
          <PlateTextField />
          <Buttons>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button stretch variant="secondary" onClick={handleFinished}>
                  <Trans>Back</Trans>
                  <IdleTimerBar />
                </Button>
                {hasUnpaid && (
                  <PayButtonContainer>
                    <Button
                      onClick={executePayment}
                      loading={paymentStatus === "loading"}
                      stretch
                    >
                      <Trans>Pay</Trans> <Euros cents={price} />
                    </Button>
                    {!isTerminal && (
                      <StripeText>
                        Powered&nbsp;by&nbsp;
                        <StripeIcon viewBox="18 10 84 38" />
                      </StripeText>
                    )}
                  </PayButtonContainer>
                )}
                {!hasUnpaid && hasPending && (
                  <PayButtonContainer>
                    <Button
                      onClick={executeLeave}
                      loading={leaveStatus === "loading"}
                      stretch
                    >
                      <Trans>Stop parking</Trans>
                    </Button>
                  </PayButtonContainer>
                )}
              </>
            )}
          </Buttons>
        </WidthContainerNarrow>

        {!loading && !hasResults ? (
          <>
            {error ? (
              <>
                <StatusIcon icon="error" />
                <WidthContainer>
                  <Alert>
                    <h3>
                      <Trans>Parking search failed.</Trans>
                    </h3>
                  </Alert>
                </WidthContainer>
              </>
            ) : (
              <>
                <WidthContainerNarrow>
                  <SearchResult>
                    <FoundParkings>
                      <Trans>It seems you don&apos;t have unpaid parking</Trans>
                    </FoundParkings>
                  </SearchResult>
                </WidthContainerNarrow>
                <WidthContainer>
                  <InfoContainer>
                    <InfoElement>
                      <KeyboardIcon />
                      <p>
                        <Trans>
                          Either you have paid it already or your parking is
                          free. If in doubt, you could check that the license
                          plate number you typed is correct.
                        </Trans>
                      </p>
                    </InfoElement>
                    {!isTerminal && (
                      <InfoElement>
                        <ParkingIcon />
                        <p>
                          <Trans>
                            Check that parkkimaksu.fi is supported in the
                            parking hall
                          </Trans>
                        </p>
                      </InfoElement>
                    )}
                  </InfoContainer>
                </WidthContainer>
              </>
            )}
          </>
        ) : (
          ""
        )}
        {!loading && hasResults ? (
          <WidthContainer>
            <SearchResultWithInfo>
              <Result>
                {hasUnpaid && (
                  <div>
                    <FoundParkings>
                      {visitIds.length !== 1 ? (
                        <Trans>
                          You have <b>{visitIds.length}</b> unpaid parkings with
                          a total price of{" "}
                          <b>
                            <Euros cents={price} />
                          </b>
                        </Trans>
                      ) : (
                        <Trans>
                          You have <b>1</b> unpaid parking with a total price of{" "}
                          <b>
                            <Euros cents={price} />
                          </b>
                        </Trans>
                      )}
                    </FoundParkings>

                    {feePerVisit > 0 && (
                      <p>
                        {visitIds.length !== 1 ? (
                          <Trans>
                            Includes a <Euros cents={feePerVisit} /> service fee
                            for each parking.
                          </Trans>
                        ) : (
                          <Trans>
                            Includes a <Euros cents={feePerVisit} /> service
                            fee.
                          </Trans>
                        )}
                      </p>
                    )}
                  </div>
                )}
                {hasPending && (
                  <div>
                    <FoundParkings>
                      <FreeRemaining end={freeParkingEnd} />
                    </FoundParkings>
                  </div>
                )}
              </Result>

              <InfoElement>
                <ReceiptIcon />
                <p>
                  {hasUnpaid && (
                    <Trans>
                      Parking can be paid after leaving or 20 minutes before.
                    </Trans>
                  )}
                  {!hasUnpaid && hasPending && (
                    <Trans>
                      Parking can be stopped 20 minutes before leaving.
                    </Trans>
                  )}
                </p>
              </InfoElement>
            </SearchResultWithInfo>
          </WidthContainer>
        ) : (
          ""
        )}
      </Content>
      <BrandAwareFooter />
    </Container>
  );
};

const PayButtonContainer = styled.div`
  width: 100%;
`;

const StripeText = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 40px;
  font-size: var(--fs-small);
  color: var(--text-secondary);
  justify-content: center;
  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
  & > svg {
    width: 46px;
    height: 23px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: calc(var(--margin) * 2) 0px;
  & > * {
    align-self: flex-start;
  }
`;

export const TicketContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FoundParkings = styled.p`
  font-size: var(--fs-biggish);
  color: var(--text-highlight);
`;

const SearchResultWithInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

const SearchResult = styled.div`
  text-align: center;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  margin-top: 40px;
  fill: var(--text-primary);
  font-size: var(--fs-smallish);
`;

const InfoElement = styled.div`
  padding-top: 5px;
  display: flex;
  gap: 20px;
  svg {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const Result = styled.div`
  display: grid;
  gap: 20px;
`;
