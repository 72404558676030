import { Trans } from "@lingui/macro";
import { Euros } from "components/Euros";
import styled from "styled-components";
import { useVisits } from "../../store/useVisits";

export const TerminalPaymentForm = () => {
  const { totalPrice } = useVisits();

  return (
    <StyledForm>
      <h2>
        <Trans>Card payment:</Trans> <Euros cents={totalPrice} />
      </h2>
      <PaymentFormWrapper>
        <Trans>
          Please follow the on-screen prompts on the payment terminal.
        </Trans>
      </PaymentFormWrapper>
    </StyledForm>
  );
};

const StyledForm = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 1px;
`;

const PaymentFormWrapper = styled.div`
  flex-grow: 1;
  margin: 20px 0;
`;
