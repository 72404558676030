import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const WidthContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const WidthContainerNarrow = styled(WidthContainer)`
  max-width: 400px;
`;
