import styled, { css } from "styled-components";
import { useIdleTimeout } from "./IdleTimeoutProvider";

export const IdleTimerBar = () => {
  const { timer } = useIdleTimeout();
  const now = new Date();
  const isLoading = timer && now.getTime() < timer.end.getTime();

  if (!isLoading) return <ProgressBar />;

  const total = timer.end.getTime() - timer.start.getTime();
  const elapsed = now.getTime() - timer.start.getTime();
  const remaining = total - elapsed;
  const initialProgress = Math.round(elapsed / total) * 100;
  // 10 seconds before end or after start, whichever is earlier.
  const visibleAtElapsed = Math.min(total - 10000, 10000);
  const visibleAtPercentage = Math.max(
    1,
    Math.min(99, Math.round((visibleAtElapsed / total) * 100))
  );

  return (
    <ProgressBar
      className="progressBar"
      key={timer.end.getTime()}
      isLoading={isLoading}
      progress={initialProgress}
      visibleAtPercentage={visibleAtPercentage}
      remaining={remaining}
    />
  );
};

type Props = {
  progress?: number;
  remaining?: number;
  visibleAtPercentage?: number;
  isLoading?: boolean;
};

const ProgressBar = styled.div<Props>`
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @keyframes timerAnimate {
    0% { width: ${(p) => p.progress || 0}%; opacity: 0 }
    ${(p) => p.visibleAtPercentage || 1}% { opacity: 0 }
    100% { width: 100%; opacity: 1 }
  }

  &:before {
    content: "";
    display: block;
    background-color: var(--text-primary);
    height: 100%;
    width: 100%;
    visibility: visible;

    ${(p) =>
      p.isLoading
        ? css`
            animation-duration: ${p.remaining}ms;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: timerAnimate;
            animation-direction: normal;
          `
        : `
            width: 100%;
            visibility: hidden;
          `}
`;
