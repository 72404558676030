import { Container, WidthContainer } from "components/Container";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import countries from "i18n-iso-countries";
import enCountriesLocale from "i18n-iso-countries/langs/en.json";
import styled from "styled-components";
import { BotView } from "views/bot/BotView";
countries.registerLocale(enCountriesLocale);

export const CustomerServicePage = () => {
  return (
    <ContainerStyled>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <BotView bot="parking" />
      <BrandAwareFooter />
    </ContainerStyled>
  );
};

const HeaderContainer = styled(WidthContainer)`
  margin-bottom: 30px;
`;

const ContainerStyled = styled(Container)`
  min-height: 100%;
  overflow: auto;
`;
