import { Trans } from "@lingui/macro";
import styled from "styled-components";
import { Euros } from "./Euros";

interface TicketProps {
  parking: string;
  status: React.ReactNode;
  start: string;
  duration: string;
  fee: number | null | undefined;
}
export const PaymentTicket: React.FC<TicketProps> = ({
  parking,
  status,
  start,
  duration,
  fee,
}) => {
  return (
    <TicketContainer>
      <TicketInfoContainer>
        <ElementLeft>
          <Title>
            <Trans>Parking</Trans>
          </Title>
          <ParkingInfo>{parking}</ParkingInfo>
        </ElementLeft>
        <ElementRight>
          <Title>
            <Trans>Status</Trans>
          </Title>
          <StatusInfo>{status}</StatusInfo>
        </ElementRight>
      </TicketInfoContainer>
      <TicketInfoContainer>
        <ElementLeft>
          <Title>
            <Trans>Start</Trans>
          </Title>
          <StartInfo>{start}</StartInfo>
        </ElementLeft>
        <ElementRight>
          <Title>
            <Trans>Duration</Trans>
          </Title>
          <DurationInfor>{duration}</DurationInfor>
        </ElementRight>
      </TicketInfoContainer>
      <TicketPaymentContainer>
        <Price>
          {fee ? (
            <Euros cents={fee} />
          ) : (
            <Trans comment="Free of charge">Free</Trans>
          )}
        </Price>
      </TicketPaymentContainer>
    </TicketContainer>
  );
};

const TicketContainer = styled.div`
  background-color: var(--card-bg);
  border-radius: 10px;
  width: 450px;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 480px) {
  }
`;

const TicketInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: calc(var(--margin) / 2) 0;
  padding: 0 var(--margin);
  gap: var(--margin);

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TicketPaymentContainer = styled.div`
  margin-top: 15px;
  background-image: url("data:image/svg+xml,%3csvg
    width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e

      %3ccircle cx='0%25' cy='50%25' r='15' fill='${({ theme }) =>
    theme.colors.background}' /%3e

      %3ccircle cx='100%25' cy='50%25' r='15' fill='${({ theme }) =>
    theme.colors.background}' /%3e

    %3c/svg%3e
  ");
  font-size: var(--fs-biggish);
  font-weight: bold;
`;

const Price = styled.div`
  margin: 0 20px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg
    width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e

      %3cline x1='0%25' y1='0%25' x2='100%25' y2='0%25' fill='none' stroke-width='1' stroke-dasharray='4, 6' stroke-dashoffset='0' stroke-linecap='square'
      stroke='${({ theme }) => theme.colors.textSecondary}' /%3e
    %3c/svg%3e
  ");
`;

const ElementLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const ElementRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
`;

const Title = styled.div`
  color: var(--text-secondary);
  text-transform: uppercase;
  font-size: var(--fs-small);
`;

const ParkingInfo = styled.div`
  @media (min-width: 481px) {
    font-size: var(--fs-biggish);
  }

  text-wrap: nowrap;
  font-weight: 400;
`;

const StatusInfo = styled.div`
  @media (min-width: 481px) {
    font-size: var(--fs-biggish);
  }
  text-wrap: nowrap;
  font-weight: 300;
  color: var(--text-secondary);
`;

const StartInfo = styled.div`
  text-wrap: nowrap;
  color: var(--text-secondary);
`;

const DurationInfor = styled.div`
  text-wrap: nowrap;
  font-weight: bold;
`;
