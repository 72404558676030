import axios from "axios";
import { getTerminalToken } from "common/localStorage";
import { backendUrl } from "config";
import { toast } from "react-toastify";

export * from "axios";

const config = {
  baseURL: backendUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

const client = axios.create(config);
export const terminalClient = axios.create(config);

terminalClient.interceptors.request.use(async (req) => {
  const token = getTerminalToken();
  if (!token) return req;

  if (!req.headers) req.headers = {};
  req.headers.Authorization = `Bearer ${token}`;
  return req;
});

export const handleError = (error: unknown, msg?: string) => {
  if (axios.isCancel(error)) return;
  if (msg) toast.error(msg);
  throw error;
};

export const ignoreStatusCodes = (error: unknown, statusCodes: number[]) => {
  if (axios.isCancel(error)) return;
  if (axios.isAxiosError(error)) {
    if (statusCodes.includes(error.response?.status || 0)) return;
  }
  throw error;
};

export default client;
