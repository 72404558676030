import client, { terminalClient } from "api/client";
import { TerminalInfo } from "common/integration/nets/types";
import {
  NetsPaymentInput,
  NetsPaymentResponse,
  NetsPaymentStatus,
  StripeConfigResponse,
  StripeCreateIntentResponse,
} from "common/models/payments/types";
import {
  createPaymentIntent,
  debugPaymentSuccess,
  netsPaymentStatus,
  netsStartPayment,
  netsTerminalDetails,
  stripeConfig,
} from "./apiPaths";

export async function debugSetPaidVisits(visits: number[]) {
  const response = await terminalClient.post<{ paymentId: string }>(
    debugPaymentSuccess,
    { visits }
  );
  return response.data;
}

export async function getStripeConfig() {
  const response = await client.get<StripeConfigResponse>(stripeConfig);
  return response.data;
}

export async function createStripePaymentIntent(visits: number[]) {
  const payload = { visits };
  const response = await client.post<StripeCreateIntentResponse>(
    createPaymentIntent,
    payload
  );
  return response.data;
}

export async function startNetsPayment(payload: NetsPaymentInput) {
  const response = await terminalClient.post<NetsPaymentResponse>(
    netsStartPayment,
    payload
  );
  return response.data;
}

export async function getNetsPaymentStatus() {
  const response = await terminalClient.get<NetsPaymentStatus>(
    netsPaymentStatus
  );
  return response.data;
}

export async function getNetsTerminalDetails() {
  const response = await terminalClient.get<TerminalInfo>(netsTerminalDetails);
  return response.data;
}
