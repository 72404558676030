import axios, { CancelTokenSource } from "axios";
import { Bot } from "chatbotApi/apiPaths";
import { botClient, ChatResponse } from "chatbotApi/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Chatbot } from "views/bot/components/Chatbot";

type Message = {
  sender: string;
  content: string;
};

type Props = {
  bot: Bot;
};

export const BotView = ({ bot }: Props) => {
  const client = useMemo(() => botClient(bot), [bot]);

  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [chatId, setChatId] = useState<string>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const firstRequest = useRef<CancelTokenSource>();

  const updateChatHistory = useCallback(
    (messageContent: string, isUser: boolean) => {
      const username = isUser ? "Me" : "Parkkimaksu Bot";
      const newMessage = { sender: username, content: messageContent };
      setChatHistory((prevMessages) => [...prevMessages, newMessage]);
    },
    []
  );

  const sendMessage = useCallback(
    async (message: string) => {
      updateChatHistory(message, true);
      setLoading(true);
      const questionToChatbot = { question: message };
      let response: ChatResponse;
      try {
        response = await (chatId
          ? client.askInChat(chatId, questionToChatbot)
          : client.startChat(questionToChatbot));
        setLoading(false);
        setError(false);
      } catch (e) {
        setLoading(false);
        setError(true);
        return;
      }
      if (!chatId) setChatId(response.chat_id);
      if (response.response) updateChatHistory(response.response, false);
      if (response.conversation_has_ended) setInitialized(false);
    },
    [updateChatHistory, chatId, client]
  );

  useEffect(() => {
    if (!initialized) {
      setChatId(undefined);
      setLoading(true);
      if (firstRequest.current) firstRequest.current.cancel();
      firstRequest.current = axios.CancelToken.source();
      client
        .getFirstMessage({
          cancelToken: firstRequest.current.token,
        })
        .then((response) => {
          setLoading(false);
          if (response.message) {
            setError(false);
            updateChatHistory(response.message, false);
            setInitialized(true);
          } else {
            setError(true);
            console.error("Expected first message from bot.");
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          setLoading(false);
          setError(true);
          console.error("Error initializing:", error);
        });
    }
  }, [updateChatHistory, initialized, client]);

  return (
    <ChatbotContainer>
      <Chatbot
        messages={
          loading
            ? [
                ...chatHistory,
                { sender: "Parkkimaksu Bot", content: "Generating..." },
              ]
            : error
            ? [
                {
                  sender: "Parkkimaksu Bot",
                  content: "There was an error, please try again later.",
                },
              ]
            : chatHistory
        }
        onSend={loading || error ? undefined : sendMessage}
        loading={loading}
      />
    </ChatbotContainer>
  );
};

const ChatbotContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 65%;
`;
