import {
  getUsername,
  getTokens,
  getUserId,
  getUserType,
} from "common/localStorage";
import { ChildrenOnly } from "common/types";
import React, { useContext, useMemo } from "react";
import { UserType } from "../../../backend/src/models/users/types";

type AuthContextType = {
  isAuth: boolean;
  username: string | undefined | null;
  userId: string | null;
  userType: UserType | null;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: ChildrenOnly) => {
  const { access, refresh } = getTokens();
  const isAuth = Boolean(access && refresh);
  const username = getUsername();
  const userId = getUserId();
  const userType = getUserType();

  const value = useMemo(
    () => ({
      isAuth,
      username,
      userId,
      userType,
    }),
    [isAuth, userId, userType, username]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("useAuth must be used within AuthContext");
  return context;
};
