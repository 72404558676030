import { Trans } from "@lingui/macro";
import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

type Props = {
  end: string | undefined;
};

const secondsUntil = (end: string | undefined) =>
  end ? differenceInSeconds(new Date(end), new Date()) : 0;

export const FreeRemaining = ({ end }: Props) => {
  const [seconds, setSeconds] = useState(secondsUntil(end));
  useEffect(() => {
    const interval = setInterval(() => setSeconds(secondsUntil(end)), 1000);

    return () => clearInterval(interval);
  }, [end]);

  if (!end) return null;

  const minutes = Math.floor(seconds / 60);
  if (minutes === 1)
    return (
      <Trans>
        Your ongoing parking is free for <b>1</b> minute.
      </Trans>
    );

  if (minutes === 0)
    return (
      <Trans>
        Your ongoing parking is free for <b>less than one</b> minute.
      </Trans>
    );

  if (minutes < 0)
    return <Trans>Your ongoing parking is no longer free.</Trans>;

  return (
    <Trans>
      Your ongoing parking is free for <b>{minutes}</b> minutes.
    </Trans>
  );
};
