import { isTerminal } from "common/utils";
import { SUPPORT_EMAIL } from "config";
import { useTerminalSettings } from "store/useTerminalSettings";
import styled from "styled-components";
import { WidthContainer } from "./Container";
import { LanguageSelection } from "./LanguageSelection";

type FooterProps = {
  show6senseInfo?: boolean;
};

export const Footer = ({ show6senseInfo = true }: FooterProps) => (
  <FooterElement>
    <BottomMenu>
      {show6senseInfo && <Left>© 2024 Beconnected</Left>}
      {isTerminal && (
        <Center>
          <LanguageSelection openUp={true} />
        </Center>
      )}
      {show6senseInfo && <Right>{SUPPORT_EMAIL}</Right>}
    </BottomMenu>
  </FooterElement>
);

export const BrandAwareFooter = () => {
  const { settings } = useTerminalSettings();
  return <Footer show6senseInfo={!settings?.brandingImgUrl} />;
};

const Left = styled.div`
  justify-self: left;
`;
const Center = styled.div`
  justify-self: center;
`;
const Right = styled.div`
  justify-self: right;
`;

const FooterElement = styled.div`
  padding: var(--margin) var(--margin) 0;
`;

const BottomMenu = styled(WidthContainer)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: var(--margin) 0;
  font-size: var(--fs-small);
  color: var(--text-secondary-link);
`;
