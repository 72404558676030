import { Loader } from "@asantech/common/react/Loader";
import styled from "styled-components";
import { Container, WidthContainer } from "./Container";

export const PageLoader = () => (
  <Container>
    <StyledWidthContainer>
      <Loader />
    </StyledWidthContainer>
  </Container>
);

const StyledWidthContainer = styled(WidthContainer)`
  flex-grow: 1;
`;
