import { isUserType } from "../../../backend/src/models/users/types";

export const setTokens = (access: string, refresh: string) => {
  localStorage.setItem("accessToken", access);
  localStorage.setItem("refreshToken", refresh);
};

export const getTokens = () => ({
  access: localStorage.getItem("accessToken"),
  refresh: localStorage.getItem("refreshToken"),
});

export const setUserId = (userId: string) => {
  localStorage.setItem("userId", userId);
};

export const getUserId = () => localStorage.getItem("userId");

export const setUsername = (username: string) => {
  localStorage.setItem("username", username);
};

export const getUsername = () => localStorage.getItem("username");

export const setUserType = (type: string) => {
  localStorage.setItem("userType", type);
};

export const setHallsOperatorId = (operatorId: string) => {
  localStorage.setItem("hallOperatorId", operatorId);
};

export const getHallsOperatorId = () => localStorage.getItem("hallOperatorId");

export const getUserType = () => {
  const userType = localStorage.getItem("userType");
  return isUserType(userType) ? userType : null;
};

export const getLanguage = () => localStorage.getItem("language");
export const setLanguage = (language: string) =>
  localStorage.setItem("language", language);

export const getTerminalToken = () => localStorage.getItem("terminalToken");
export const setTerminalToken = (token: string) =>
  localStorage.setItem("terminalToken", token);
export const getDebugTerminalPayment = () =>
  localStorage.getItem("debugTerminalPayment") === "true";
