import styled from "styled-components";

export const Alert = styled.div<{ success?: boolean }>`
  border-radius: 10px;
  border: 1px solid #e31d02;
  background: #26120f;
  letter-spacing: 0.36px;
  padding: 15px;
  margin-bottom: 35px;

  ${(p) =>
    p.success
      ? `
  background: #1A2919;
  border-color: #2DC220;
  `
      : ""}

  h3 {
    font-size: 18px;
    padding-top: 8px 0;
    letter-spacing: 0.36px;
    text-align: center;
    line-height: 25px;
    padding-top: 7px;
    top: -7px;
    position: relative;
  }

  ul {
    font-weight: normal;
    line-height: 140%;
    letter-spacing: 0.36px;
    margin: 0;
    margin-left: -13px;
    margin-bottom: -5px;

    li {
      padding: 10px;
      padding-left: 4px;
    }
  }
`;
