import { useMaskedHookFormInput } from "@asantech/common/react/common/hooks/useMaskedHookFormInput";
import { normalizePlateText } from "@asantech/common/react/common/plateTextAdapters";
import { Trans } from "@lingui/macro";
import { Pages } from "common/pages";
import { Plate } from "common/types";
import { isTerminal } from "common/utils";
import { Button } from "components/Button";
import {
  Container,
  WidthContainer,
  WidthContainerNarrow,
} from "components/Container";
import { Content } from "components/Content";
import { BrandAwareFooter } from "components/Footer";
import { Header } from "components/Header";
import { Label } from "components/Label";
import { PlateCountryField } from "components/PlateCountryField";
import { PlateTextField } from "components/PlateTextField";
import { VirtualKeyboard } from "components/VirtualKeyboard";
import { chatbotBackendUrl } from "config";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useTerminalSettings } from "store/useTerminalSettings";
import styled from "styled-components";
import ChatbotIcon from "../assets/images/chatbot-icon.png";
import { useVisits } from "../store/useVisits";

type FormData = Plate;

export const MainPage = () => {
  const { plate, unpaidData, loading, resetPlate, refreshUnpaidData } =
    useVisits();
  const { settings } = useTerminalSettings();
  const history = useHistory();
  const { control, formState, register, handleSubmit, setValue, watch, reset } =
    useForm<FormData>({
      mode: "all",
      defaultValues: plate,
    });
  const textRegister = useMaskedHookFormInput(normalizePlateText, register);
  const handleCheckParkingsClick = async (formData: FormData) => {
    const result = await refreshUnpaidData({ ...formData });
    if ((result?.countries.length || 0) < 2) history.push(Pages.Unpaid);
  };

  const plateText = watch("plateText");
  const setPlateText = useCallback(
    (value: string) => {
      setValue("plateText", value);
    },
    [setValue]
  );

  useEffect(() => {
    resetPlate();
  }, [resetPlate]);

  useEffect(() => {
    reset(plate);
  }, [reset, plate]);

  return (
    <ContainerStyled backgroundUrl={settings?.brandingImgUrl}>
      <Header />
      <Form onSubmit={handleSubmit(handleCheckParkingsClick)}>
        <ContentStyled>
          <WidthContainer>
            <Label>
              <Trans>Pay while parked or after exiting.</Trans>
              <br />
              <Trans>Enter your registration to continue:</Trans>
            </Label>
          </WidthContainer>
          <WidthContainerNarrow>
            <PlateTextField register={textRegister} formState={formState} />
            {unpaidData.countries.length > 1 && (
              <>
                <Label>
                  <Trans>Country:</Trans>
                </Label>
                <PlateCountryField
                  control={control}
                  countryCodes={unpaidData.countries || []}
                  invalid={!!formState.errors.plateCountry}
                />
              </>
            )}
          </WidthContainerNarrow>
          {isTerminal && (
            <KeyboardWrapper>
              <VirtualKeyboard value={plateText} setValue={setPlateText} />
            </KeyboardWrapper>
          )}
          <WidthContainerNarrow>
            <ContinueButton
              onClick={handleSubmit(handleCheckParkingsClick)}
              loading={loading}
              stretch
            >
              <Trans>Continue</Trans>
            </ContinueButton>
          </WidthContainerNarrow>
        </ContentStyled>
      </Form>

      {!isTerminal && chatbotBackendUrl && (
        <Link to={Pages.CustomerService}>
          <ChatbotIconBox>
            <img src={ChatbotIcon} alt="Chatbot Icon" />
          </ChatbotIconBox>
        </Link>
      )}
      <BrandAwareFooter />
    </ContainerStyled>
  );
};
const ContinueButton = styled(Button)`
  margin-top: 40px;
`;
const KeyboardWrapper = styled.div`
  margin-top: 40px;
`;

const ContainerStyled = styled(Container)<{
  backgroundUrl: string | null | undefined;
}>`
  ${({ backgroundUrl }) =>
    backgroundUrl
      ? `background: url(${backgroundUrl}) top center no-repeat`
      : ""};
  min-height: 100%;
  overflow: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ContentStyled = styled(Content)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  overflow: initial;
`;

const ChatbotIconBox = styled.div`
  position: fixed;
  bottom: 10%;
  right: 10%;
  z-index: 1000;

  img {
    border-radius: 5px;
    height: auto;
    width: 80px;
    min-height: 29px;
    margin-right: 14px;
  }

  span {
    vertical-align: middle;
  }
`;
