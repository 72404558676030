import { ErrorObject } from "@asantech/common/express/openapiModelValidator";
import { maxLengthValidator } from "@asantech/common/react/common/formUtils";
import { handleError } from "api/client";
import { AxiosError } from "axios";
import { useCallback } from "react";
import {
  DeepMap,
  FieldError,
  FieldPath,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormTrigger,
} from "react-hook-form";

export const PLATE_COUNTRY_CODE_LENGTH = 3;
export const PLATE_TEXT_MAX_LENGTH = 10;

export const getServerError = (error: unknown) => {
  if (error instanceof AxiosError) return error.response?.data?.error;
};

/**
 * Attempts to display validation errors using the setError function
 * @returns False if the error wasn't a validation error, otherwise True
 */
export const setInputValidationErrors = <T extends FieldValues>(
  axiosError: AxiosError<ErrorObject[]>,
  setError: UseFormSetError<T>,
  pathPrefix = ""
) => {
  const message = axiosError.response?.data?.[0];
  if (!message?.keyword) return false;

  const errors = (axiosError.response?.data || []) as ErrorObject[];

  errors.forEach((error) => {
    const path = error.instancePath
      ? error.instancePath
          .split("/")
          .filter((p) => !!p)
          .map((part) =>
            Number(part).toString() === part ? `[${part}]` : part
          )
      : [error.params.missingProperty];

    if (pathPrefix) path.unshift(pathPrefix);

    const field = path.join(".") as FieldPath<T>;

    setError(field, {
      type: "server",
      message: error.message || "Field is invalid",
    });
  });
  return true;
};

export const handleFormSubmitError = <T extends FieldValues>(
  err: unknown,
  setError: UseFormSetError<T>,
  pathPrefix?: string,
  errorMessage?: string
) => {
  if (
    !setInputValidationErrors(
      err as AxiosError<ErrorObject[]>,
      setError as UseFormSetError<T>,
      pathPrefix
    )
  ) {
    let error = "Something went wrong.";

    if (err instanceof Error) error = err.message;
    if (err instanceof AxiosError) error = getServerError(err) || error;

    return handleError(error, errorMessage || error);
  }
};

export const clearFieldErrors = async <T extends FieldValues>(
  errors: DeepMap<FieldValues, FieldError>,
  clearErrors: UseFormClearErrors<T>,
  trigger: UseFormTrigger<T>
) => {
  if (Object.keys(errors).length !== 0) {
    clearErrors();
    // Trigger re-validation manually to allow form re-submission
    await trigger();
  }
};

export const useClearErrorsHandler = <T extends FieldValues>(
  errors: DeepMap<FieldValues, FieldError>,
  clearErrors: UseFormClearErrors<T>,
  trigger: UseFormTrigger<T>
) => {
  return useCallback(() => {
    clearFieldErrors(errors, clearErrors, trigger);
  }, [errors, clearErrors, trigger]);
};

export const emailValidationPattern = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email",
  },
  ...maxLengthValidator(254),
};
