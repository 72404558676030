import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/svgs/check-icon.svg";
import { ReactComponent as CrossIcon } from "../assets/svgs/cross-icon.svg";
import { ReactComponent as DocIcon } from "../assets/svgs/doc-icon.svg";
import { ReactComponent as HappyIcon } from "../assets/svgs/happy-icon.svg";
import { WidthContainer } from "./Container";

type StatusIconProps = {
  variant?: boolean;
  icon?: "ok" | "error" | "happy";
};

export const StatusIcon = ({ icon, variant }: StatusIconProps) => {
  const iconElement =
    icon === "error" ? (
      <CrossIcon />
    ) : icon === "happy" ? (
      <HappyIcon />
    ) : (
      <CheckIcon />
    );
  return (
    <StatusIconContainer>
      <WidthContainer>
        <IconBackground error={icon === "error"}>
          {variant ? (
            <>
              <DocIcon />
              {iconElement}
            </>
          ) : (
            iconElement
          )}
        </IconBackground>
      </WidthContainer>
    </StatusIconContainer>
  );
};

const StatusIconContainer = styled.div`
  text-align: center;
  padding-top: var(--margin);
  padding-bottom: var(--margin);

  div {
    line-height: 0;
  }
`;

const IconBackground = styled.div<{ error?: boolean }>`
  width: max(min(160px, 15dvh), 60px);
  height: max(min(160px, 15dvh), 60px);
  border-radius: 50%;
  background: ${({ error }) =>
    error ? "var(--alert-fail)" : "var(--alert-success)"};
  outline: max(min(16px, 1.5dvh), 6px) solid rgba(0, 0, 0, 0.3);
  outline-offset: calc(-1 * (max(min(16px, 1.5dvh), 6px)));
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    width: 50%;
    height: 50%;
  }

  svg .primary {
    fill: ${({ error }) =>
      error ? "var(--alert-fail)" : "var(--alert-success)"} !important;
  }

  svg .primary-stroke {
    stroke: ${({ error }) =>
      error ? "var(--alert-fail)" : "var(--alert-success)"} !important;
  }
  svg + svg {
    position: absolute;
    right: 15%;
    bottom: 15%;
    width: 25%;
    height: 25%;
  }
`;
