export type Encrypted = {
  encrypted: string;
  tag: string;
  iv: string;
  sort: string;
};

export const openApiEncrypted = {
  type: "object",
  required: ["encrypted", "tag", "iv", "sort"],
  properties: {
    encrypted: {
      type: "string",
      description: "Encrypted data as a base64 encoded string.",
    },
    tag: {
      type: "string",
      description: "Authentication tag as a base64 encoded string.",
    },
    iv: {
      type: "string",
      description: "Initialization vector as a base64 encoded string.",
    },
    sort: {
      type: "string",
      description: "Plaintext string for sorting.",
    },
  },
};

export const isEncrypted = (obj: Record<string, unknown>): obj is Encrypted => {
  return (
    !!obj.encrypted &&
    !!obj.tag &&
    !!obj.iv &&
    !!obj.sort &&
    Object.values(obj).every((val) => typeof val === "string")
  );
};
